import React from 'react'
import { InputGroup, Form} from 'react-bootstrap'
import Logo from '../../../Images/magfitLogoMd.png'
import './styles.scss'

export const PassKey = () => {
  return (
      <div className="passkey-body">
        <div className="passkey-section">
          <img id="passkey-magfit-logo" src={Logo} alt="magfitLogo" />
          <InputGroup className="mb-3">
            <InputGroup.Text id="passkey-lock-box">
              <i className="bi bi-lock"></i>
            </InputGroup.Text>
            <Form.Control
            id="passkey-input"
              placeholder="Pass Key"
              aria-label="passkey"
              aria-describedby="passkey-lock-box"
            />
          </InputGroup>
          <button className='custom-button'>
            Login
          </button>
        </div>
      </div>
  )
}
