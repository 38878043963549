import { useState, useEffect } from 'react';
import { PageTemplate } from '../../PageTemplate';
import './styles.scss';
import { Spinner } from 'react-bootstrap';
import { collection, query, where, getDocs, Timestamp, getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { useStoreContext } from '../../../Utilities/globalState';
import { Progress, Rate, Empty, Typography } from 'antd';

interface Review {
    id: string;
    classesRating: number;
    cleanlinessRating: number;
    communityRating: number;
    createdAt: Timestamp;
    friendlinessRating: number;
    gymID: string;
    reviewText: string;
    trainersRating: number;
    userUID: string;
}

interface User {
    firstName: string;
    lastName: string;
    age: number;
    gender: string;
}
type NumericReviewKey = Exclude<keyof Review, 'id' | 'createdAt' | 'gymID' | 'reviewText' | 'userUID'>;

export const Reviews = () => {
    const [state] = useStoreContext();
    const gymId = state.currentUser?.gymId;
    const [reviews, setReviews] = useState<Review[]>([]);
    const [users, setUsers] = useState<{ [key: string]: User }>({});
    const [loading, setLoading] = useState(true);
    const [totalReviews, setTotalReviews] = useState(0);
    const [averageRating, setAverageRating] = useState(0);
    const [averageClassesRating, setAverageClassesRating] = useState(0);
    const [averageCleanlinessRating, setAverageCleanlinessRating] = useState(0);
    const [averageCommunityRating, setAverageCommunityRating] = useState(0);
    const [averageFriendlinessRating, setAverageFriendlinessRating] = useState(0);
    const [averageTrainersRating, setAverageTrainersRating] = useState(0);

    useEffect(() => {
        const fetchReviews = async () => {
            if (!gymId) return;
            setLoading(true);

            try {
                const q = query(collection(db, 'GymReviews'), where('gymID', '==', gymId));
                const querySnapshot = await getDocs(q);
                const reviewsList = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() } as Review))
                    .sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime());

                setReviews(reviewsList);

                const uniqueUserUIDs = Array.from(new Set(reviewsList.map(review => review.userUID)));
                const usersData: { [key: string]: User } = {};

                for (const userUID of uniqueUserUIDs) {
                    const userDoc = doc(db, 'Users', userUID);
                    const userSnapshot = await getDoc(userDoc);
                    if (userSnapshot.exists()) usersData[userUID] = userSnapshot.data() as User;
                }
                setUsers(usersData);

                const total = reviewsList.length;
                const calculateAverage = (key: NumericReviewKey) =>
                total ? reviewsList.reduce((sum, review) => sum + (review[key] as number), 0) / total : 0;

                setTotalReviews(total);
                setAverageRating(total ? calculateAverage('classesRating') : 0);
                setAverageClassesRating(calculateAverage('classesRating'));
                setAverageCleanlinessRating(calculateAverage('cleanlinessRating'));
                setAverageCommunityRating(calculateAverage('communityRating'));
                setAverageFriendlinessRating(calculateAverage('friendlinessRating'));
                setAverageTrainersRating(calculateAverage('trainersRating'));
            } catch (error) {
                console.error("Error fetching reviews: ", error);
            }

            setLoading(false);
        };

        fetchReviews();
    }, [gymId]);

    const formatTimestamp = (timestamp: Timestamp) => {
        const date = timestamp.toDate();
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    const RatingProgress = ({ rating, label }: { rating: number; label: 'Classes' | 'Cleanliness' | 'Community' | 'Friendliness' | 'Trainers' }) => {
        const strokeColors = {
            Classes: '#FFA500',
            Cleanliness: '#0000FF',
            Community: '#800080',
            Friendliness: '#4caf50',
            Trainers: '#FF0000',
        };
    
        return (
            <div className="reviews-progress">
                <p className='label'>{label}</p>
                <Progress 
                    percent={(rating / 5) * 100} 
                    showInfo={false} 
                    strokeColor={strokeColors[label as keyof typeof strokeColors] || '#001342'} 
                />
                <p className='rating'>{rating.toFixed(1)}</p>
            </div>
        );
    };
    

    return (
        <PageTemplate>
            <div className='community-body'>
                <h1>Reviews</h1>
                <div className='review-stats'>
                    <div className='stat-1'>
                        <p>Total Reviews</p>
                        <h3>{totalReviews}</h3>
                    </div>
                    <div className='stat-1'>
                        <p>Average Rating</p>
                        <div className='star-rating'>
                            <h3>{averageRating.toFixed(2)}</h3>
                            <Rate disabled allowHalf value={averageRating} />
                        </div>
                    </div>
                    <div className='stat'>
                        <RatingProgress rating={averageClassesRating} label="Classes" />
                        <RatingProgress rating={averageCleanlinessRating} label="Cleanliness" />
                        <RatingProgress rating={averageCommunityRating} label="Community" />
                        <RatingProgress rating={averageFriendlinessRating} label="Friendliness" />
                        <RatingProgress rating={averageTrainersRating} label="Trainers" />
                    </div>
                </div>
                <div className='reviews'>
                    {loading ? (
                        <div className='spinner-container'>
                            <Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
                        </div>
                    ) : (
                        reviews.length > 0 ? (
                            reviews.map(review => {
                                const overallRating = (
                                    review.classesRating + review.cleanlinessRating + review.communityRating +
                                    review.friendlinessRating + review.trainersRating
                                ) / 5;
                                const user = users[review.userUID] || { firstName: 'Unknown', lastName: 'User' };

                                return (
                                    <div key={review.id} className='review'>
                                        <div className='review-group'>
                                            <p className='username'>{user.firstName} {user.lastName}</p>
                                            <p>{formatTimestamp(review.createdAt)}</p>
                                        </div>
                                        <div className='review-group'>
                                            <Rate disabled allowHalf defaultValue={overallRating} />
                                        </div>
                                        <p>{review.reviewText}</p>
                                    </div>
                                );
                            })
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Typography.Text>No Reviews Found</Typography.Text>} />
                        )
                    )}
                </div>
            </div>
        </PageTemplate>
    );
};

