import React from 'react'
import './style.scss'
import { useStoreContext } from '../../../../Utilities/globalState'


export default function Welcome() {
    const [state, dispatch] = useStoreContext()
    return (
        <>
            <div className='welcome-body'>
                <div className='welcome-container'>
                    <h3>Hi, {state.currentUser.gymName}</h3>
                    <p>Here's how your business is doing</p>
                </div>
            </div>
        </>
    )
}