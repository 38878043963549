import React from 'react'
import './style.scss'
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface HelpProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Help: React.FC<HelpProps> = ({ setOpen }) => {
    const startTour = () => {
        setOpen(true);
    };



    return (
        <div className='help-body'>
            <div className='need-help'>
                <h3>Help Center</h3>
                <p>Learn how to get started. Take advantage of our features designed to help grow your business.</p>
                <div className='help-buttons'>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tour-tooltip">Take a tour</Tooltip>}
                    >
                        <button className="help-button tour-button" onClick={startTour}>
                            <i className="bi bi-lightbulb"></i>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tour-tooltip">Read the docs</Tooltip>}
                    >
                        <button className="help-button">
                            <Link to="https://moregyms.mintlify.app/introduction"><i className="bi bi-book"></i></Link>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tour-tooltip">Contact us</Tooltip>}
                    >
                        <button className="help-button">
                            <Link to="/settings#contact"><i className="bi bi-headset"></i></Link>
                        </button>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}
export default Help;