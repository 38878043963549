import React, {
  createContext,
  useContext
} from "react";
import {
  useModalReducer
} from "./reducers";

export const defaultUserState = {
  moreGymsPremium: null,
  dayPassLink: [],
  claimedGym: false,
  classList: [],
  localUsers: [],
  stripeData: null,
  paymentPackages: [],
  paymentData: {
    subscriptionId: "",
    stripeCustomerId: "",
    startDate: 0,
    lastInvoice: "",
    active: "",
  },
  gymType: ['CrossFit'],
  gymId: "",
  gymOwnerId: "",
  gymName: "",
  gymLogoURL: "",
  gymHeaderURL: "",
  geoPoint: {} || null,
  email: "",
  phone: "",
  passKey: "",
  address: "",
  zipCode: "",
  stateInUs: "",
  phoneNumber: "",
  website: "",
  appointmentOnly: false,
  open247: false,
  latitude: 0,
  longitude: 0,
  openHours: {
    0: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Monday"
    },
    1: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Tuesday"
    },
    2: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Wednesday"
    },
    3: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Thursday"
    },
    4: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Friday"
    },
    5: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Saturday"
    },
    6: {
      open: "09:00",
      close: "17:00",
      isOpen: false,
      day: "Sunday"
    },
  },
  instagramUsername: "",
  gymBio: "",
  legal:{
    releaseWaiver: "",
    privacyPolicy: "",
    terms: "",
    refundPolicy: ""
  },
  amenities: {
    lockers: false,
    sauna: false,
    steam: false,
    nutritionBar: false,
    nutritionCoaching: false,
    water: false,
    towels: false,
    showers: false,
    bathrooms: false,
    lounge: false,
    tvs: false,
    music: false,
    wifi: false,
    freeWifi: false,
    parking: false,
    kidArea: false,
    weightPlatforms: false,
    dogFriendly: false,
  },
  services: {
    dropInPrice: 0,
    trainers: [],
    trainersAvailable: false,
    wifiPW: "",
    wifiAvailable: false,
    dayPass: false,
    openGym: false,
    openGymInfo: "",
    nutritionCoaching: false,
    personalFilming: false,
    classesOffered: false,
    classesOfferedInfo: "",
    weightPlatforms: false,
    parking: false,
    parkingInfo: "",
    dogFriendly: false,
  },
  equipment: {
    SkiErg: false,
    WrestlingMats: false,
    CalfRaiseMachine: false,
    HackSquat: false,
    Rigs: false,
    FocusMittsAndTaiPads: false,
    KettleBells: false,
    ChalkStations: false,
    Sandbags: false,
    JumpRopes: false,
    RowingMachines: false,
    DoubleEndBags: false,
    OlympicBarbells: false,
    TricepDipBars: false,
    SpeedBags: false,
    BarbellClips: false,
    KneePadsAndElbowPads: false,
    PlyometricBoxes: false,
    ResistanceBands: false,
    StairMaster: false,
    KiloPlates: false,
    AgilityLaddersAndCones: false,
    Dumbells: false,
    CageOrOctagon: false,
    TRXBand: false,
    TurfSection: false,
    LegPressMachine: false,
    Mirrors: false,
    GymnasticRings: false,
    Headgear: false,
    MedicineBalls: false,
    PVCPipes: false,
    BattleRopes: false,
    DeclineBench: false,
    CableMachines: false,
    RopeClimb: false,
    BoxingRing: false,
    HamstringCurlMachine: false,
    Elliptical: false,
    SparringGlovesAndMitts: false,
    GroinProtectors: false,
    BumperPlates: false,
    FoamRollers: false,
    HeavyBags: false,
    MMAGloves: false,
    Treadmill: false,
    SubmissionGrapplingDummies: false,
    LegExtensionMachine: false,
    Sleds: false,
    AbMats: false,
    SquatRacks: false,
    SmithMachine: false,
    ShinGuardsAndInstepGuards: false,
    GHDMachine: false,
    PullUpBars: false,
    JudoMats: false,
    WallClimbingHolds: false,
    C2Bike: false,
    MetalPlates: false,
    AssaultBikes: false,
    FlatInclineBench: false,
    PegBoard: false
  },
  additionalPhotos: [],
  videoUrl: "",
}

const getInitialState = () => {
  const state = localStorage.getItem("magfitState");
  const defaultState = {
    currentUser: defaultUserState,
    loggedIn: false,
    navCollapse: 1,
    gymMembers: [],
    gymsList: [],
  }
  return state ? JSON.parse(state) : defaultState;
}

const StoreContext = createContext();
const {
  Provider
} = StoreContext;

const StoreProvider = ({
  value = [],
  ...props
}) => {
  const [state, dispatch] = useModalReducer(getInitialState());
  return <Provider value={
    [state, dispatch]
  } {
    ...props
    }
  />;
};

const useStoreContext = () => {
  return useContext(StoreContext);
};

export {
  StoreProvider,
  useStoreContext
};