import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { PageTemplate } from '../../PageTemplate';
import { ConfigProvider, Tabs } from 'antd';
import { Feedback } from './Feedback/index';
import { ChangePassword } from './ChangePassword';
// import { GymSettings } from './GymSettings'; // Commented out GymSettings
import PaymentSettings from './PaymentSettings';
import { Info } from './Info/index';
import { auth } from '../../../firebase-config';
import { signOut } from '@firebase/auth';
import { Plans } from './Plans/';
import './styles.scss';

export const Settings: React.FC = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState("1");


  const onTabChange = (key: string) => {
    setActiveTabKey(key);
    // Update the URL hash to reflect the active tab
    switch (key) {
      case '1':
        navigate('/settings#plan');
        break;
      case '2':
        navigate('/settings#details');
        break;
      case '3':
        navigate('/settings#password');
        break;
      case '4':
        navigate('/settings#contact');
        break;
      case '5':
        navigate('/settings#payment-settings');
        break;
      default:
        navigate('/settings#plan');
    }
  };

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      window.location.reload();
    });
  };
  const operations = <button className="logout-button" onClick={signUserOut}>Log Out</button>;

  useEffect(() => {
    // Set active tab based on URL hash
    switch (hash) {
      case '#contact':
        setActiveTabKey('4');
        break;
      case '#payment-settings':
        setActiveTabKey('5');
        break;
      case '#password':
        setActiveTabKey('3');
        break;
      case '#details':
        setActiveTabKey('2');
        break;
      case '#plan':
      default:
        setActiveTabKey('1');
        break;
    }
  }, [hash]);

  const items = [
    {
      key: '1',
      label: 'Plan',
      children: <Plans />,
    },
    {
      key: '2',
      label: 'Account Details',
      children: <Info />,
    },
    {
      key: '3',
      label: 'Password',
      children: <ChangePassword />,
    },
    {
      key: '4',
      label: 'Contact',
      children: <Feedback />,
    },
    {
      key: '5',
      label: 'Payment Settings',
      children: <PaymentSettings />,
    },
  ];
  const themeSettings = {
    token: {
      colorPrimary: '#000000',
      cardBg: '#1a1a1a',
      inkBarColor: '#ffffff',
      itemColor: 'rgba(255, 255, 255, 0.7)',
      itemActiveColor: '#ffffff',
      itemHoverColor: '#cccccc',
      cardPadding: '10px 20px',
    },
  };

  return (
    <ConfigProvider theme={themeSettings}>
      <PageTemplate>
        <Tabs activeKey={activeTabKey} onChange={onTabChange} items={items} tabBarExtraContent={operations} />
      </PageTemplate>
    </ConfigProvider>
  );
};

