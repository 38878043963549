import React, { useState } from 'react'
import { Modal, Form, FloatingLabel } from 'react-bootstrap'
import { ref, uploadBytes, getDownloadURL } from '@firebase/storage'
import { storage, db } from '../../../../firebase-config'
import { useStoreContext } from '../../../../Utilities/globalState'
import { addDoc, collection } from '@firebase/firestore'
import { GYM_MEMBERS_REF } from '../../../../Utilities/dbRefs'
import './styles.scss'
import { reportNonBlockingError } from '../../../../Utilities/helpers'

export const AddMemberModal = ({ show, hide}: any) => {
  const [state, dispatch] = useStoreContext()
  const [newMember, setNewMember] = useState({
    photo: '',
    fName: '',
    lName: '',
    email: '',
    phone: '',
    emergencyName: '',
    emergencyPhone: '',
    signupDate: new Date(),
    classesAttended: [],
    shoppingCart: [],
    trainer: true,
    classesTaught: [],
    gymId: state.currentUser.gymId,
  })

  const saveMemberToDb = async() => {
    const AllGymsMembersRef = collection(db, GYM_MEMBERS_REF)
    const docRef = await addDoc(AllGymsMembersRef, newMember)
    if (docRef) return docRef.id
    else return null
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'trainer') {
      setNewMember({
        ...newMember,
        [e.target.id]: e.target.value === 'on' ? true : false,
      })
    } else {
      setNewMember({
        ...newMember,
        [e.target.id]: e.target.value,
      })
    }
  }

  const handleAddMemberSubmit = async() => {
    // setMembers([...members, newMember])
    saveMemberToDb()
    .then(id => {
      dispatch({
        type: 'SET_CURRENT_USER',
        currentUser: {
          ...state.currentUser,
          localUsers: [...state.currentUser.localUsers, id],
        },
      })
      dispatch({
        type: 'SET_GYM_MEMBERS',
        gymMembers: [...state.gymMembers, newMember]
      })
    })
    hide(false)
    setTimeout(() => {
      window.location.reload(); // Refresh the window
    }, 1000); // Refresh after 2 seconds
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.files)
    if (e.target.files != null) {
      const imageRef = ref(storage, `GymMemberImages/${Date.now()}`)
      if (e.target.files[0] !== null)
        uploadBytes(imageRef, e.target.files[0], state.currentUser.gymOwnerId)
          .then((res) => {
            getDownloadURL(res.ref)
              .then((r) => {
                setNewMember({
                  ...newMember,
                  photo: r,
                })
              })
              .catch((err) => reportNonBlockingError(err, state, 'AddMemberModal -> handleImageUpload -> getDownloadURL(res.ref)'))
          })
          .catch((err) => {
            reportNonBlockingError(err, state, 'AddMemberModal -> handleImageUpload -> uploadBytes(imageRef, e.target.files[0], state.currentUser.gymOwnerId)')
          })
    }
  }
  const isFormValid = () => {
    return Object.values(newMember).every((value) => value !== '');
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setNewMember({ ...newMember, phone: value });
    }
  };
  const handleEmergencyPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setNewMember({ ...newMember, emergencyPhone: value });
    }
  };

  return (
    <Modal show={show} onHide={() => hide(false)} className="add-member-modal">
      <div className="add-member-body">
        <h3>New Trainer</h3>
        {/* <FloatingLabel label="User Photo">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="User Photo"
            id="photo"
            onChange={handleChange}
          ></Form.Control>
        </FloatingLabel> */}

        {/* <Form.Label> */}
          Upload Trainer Photo (.png, .gif, .jpeg)
          <Form.Control
            onChange={handleImageUpload}
            className="add-member-input"
            id="photo"
            type="file"
            accept="image/png, image/gif, image/jpeg"
          />
        {/* </Form.Label> */}

        <FloatingLabel label="First Name">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="First Name"
            id="fName"
            onChange={handleChange}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel label="Last Name">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="Last Name"
            id="lName"
            onChange={handleChange}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel label="Email">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="Email"
            id="email"
            autoComplete='email'
            onChange={handleChange}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel label="Phone">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="Phone"
            id="phone"
            autoComplete="tel"
            maxLength={10}
            value={newMember.phone}
            onChange={handlePhoneChange}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel label="Emergency Contact Name">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="Emergency Contact Name"
            id="emergencyName"
            onChange={handleChange}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel label="Emergency Contact Phone">
          <Form.Control
            className="add-member-input"
            type="text"
            placeholder="Emergency Contact Phone"
            id="emergencyPhone"
            autoComplete="tel"
            maxLength={10}
            value={newMember.emergencyPhone}
            onChange={handleEmergencyPhoneChange}
          ></Form.Control>
        </FloatingLabel>
{/* 
        <Form.Check
          type="checkbox"
          id="trainer"
          label="Gym Trainer"
          onChange={handleChange}
        /> */}

        <button
          className="custom-button member-button"
          type="button"
          onClick={handleAddMemberSubmit}
          disabled={!isFormValid()}
        >
          ADD TRAINER <i className="bi bi-plus-square"></i>
        </button>
      </div>
    </Modal>
  )
}
