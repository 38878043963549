import React, {useEffect} from 'react'
import './styles.scss'
import { addDoc ,collection} from 'firebase/firestore'
import { db } from '../../../firebase-config'
import { useStoreContext } from '../../../Utilities/globalState'

export default function ErrorPage({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: Function }) {
    const [count, setCount] = React.useState(5)
    const [state, dispatch] = useStoreContext()

    async function createErrorReport() {
        const ErrorRef = collection(db, "DashboardBlockingErrors")
        const docRef = await addDoc(ErrorRef, {
            errorMessage: error.message|| "No error",
            errorStack: error.stack || "No stack",
            errorName: error.name || "No name",
            errorCause: error.toString() || "No cause",
            user: state?.currentUser?.gymId || "No user",
            state: state.currentUser || "No state",
            date: new Date()
        })
        // if (docRef)  console.log(docRef.id)
    }
    useEffect(() => {
        createErrorReport()
    }, [])

    useEffect(() => {
        if (count === 0) {
            window.location.pathname = '/';
        }
    }, [count])

    return (
        <div className="error-body">
            <h2>Something went wrong</h2>
            <p>We're sorry, there seems to be a problem. The developers have been notified and we are working to fix it. Thanks for supporting MoreGyms!</p>
            <pre>{error.message}</pre>
            <button className='custom-button' onClick={() => {
                resetErrorBoundary()
            }}>Try Again</button>
        </div>
    )
}
