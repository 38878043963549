import { useState, useRef, useEffect, SetStateAction } from 'react';
import { InputGroup, Form, Alert } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Logo from '../../../Images/newMagfitLogo.png'
import AppPhoto from '../../../Images/2.png'
import { useStoreContext } from '../../../Utilities/globalState'
import { useLocation } from 'react-router';
import { setDoc, doc, getDoc, updateDoc } from '@firebase/firestore'
import { createUserWithEmailAndPassword } from '@firebase/auth'
import { db, auth } from '../../../firebase-config'
import { GYMS_REF } from '../../../Utilities/dbRefs';
import { parseDataForState } from '../../../Utilities/helpers';
import './styles.scss'

export default function ClaimGym() {
    const [state, dispatch] = useStoreContext()
    const [email, setEmail] = useState('')
    const [key, setKey] = useState('')
    const [loginErr, setLoginErr] = useState(false)
    const [gymLookupErr, setGymLookupErr] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordErr, setPasswordErr] = useState(false)
    const [hidePW, setHidePW] = useState(true)
    const { emailAddress } = useParams()
    const { keyCode } = useParams()
    const { hash } = useLocation()
    const navigate = useNavigate()

    async function handleSubmit(e: any) {
        if (!checkPassword()) {
            e.preventDefault()
            setPasswordErr(true);
            return null
        } else {
            setPasswordErr(false);
            return createUserWithEmailAndPassword(auth, email, password)
        }
    }

    const checkPassword = () => {
        const specialChars = new Set(['@', '#', '!', '~', '$', '?', '%', '+', '&', '*']);
        const numChars = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
        let special = false;
        let upper = false;
        let lower = false;
        let nums = false;
        let length = password.length >= 6;
        for (let char of password) {
            if (specialChars.has(char)) special = true;
            if (numChars.has(char)) nums = true;
            if (char.toLowerCase() !== char) upper = true;
            if (char.toUpperCase() !== char) lower = true;
        }
        return special && upper && lower && nums && length;
    }

    async function handleClaim(e: any) {
        e.preventDefault();
        setLoginErr(false)
        setGymLookupErr(false)
        if (email && key) {
            findClaimCode().then(data => {
                if (!data) {
                    setGymLookupErr(true)
                    return;
                }
                if (data.claimed) {
                    setGymLookupErr(true)
                    return;
                }
                if (data.email !== email) {
                    setGymLookupErr(true)
                    return;
                }
                handleSubmit(e)
                    .then(async (userCredential) => {
                        if (!userCredential) return;
                        const user = userCredential.user;
                        const gymRef = doc(db, GYMS_REF, data.gymID);
                        const gymSnap = await getDoc(gymRef);
                        if (!gymSnap.exists()) {
                            console.log("No such document!");
                            return;
                        }

                        // Update gym document with new AdminID and AdminEmail
                        await updateDoc(gymRef, {
                            "Admin ID": user.uid,
                            "Admin Email": user.email
                        });

                        // Retrieve the updated gym document
                        const updatedGymSnap = await getDoc(gymRef);
                        const updatedGymData = { id: updatedGymSnap.id, ...updatedGymSnap.data() };

                        const claimRef = doc(db, "ClaimCodes", key);
                        await setDoc(claimRef, { claimed: true }, { merge: true });

                        dispatch({
                            type: 'SET_CURRENT_USER',
                            currentUser: parseDataForState([updatedGymData.id, updatedGymData])
                        });
                        dispatch({
                            type: 'LOGIN_STATUS',
                            loggedIn: true,
                        });
                        navigate('/home');

                    }).catch((err) => {
                        setLoginErr(true);
                    });
            });
        }
    }

    async function findClaimCode() {
        if (!key || !key.length) return;
        const claimRef = doc(db, "ClaimCodes", key);
        const claimSnap = await getDoc(claimRef);
        if (claimSnap.exists()) {
            return claimSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    }

    useEffect(() => {
        if (state && state.loggedIn) {
            navigate('/')
        }
    }, [state, navigate]);


    useEffect(() => {
        if (emailAddress && keyCode) {
            setEmail(emailAddress)
            setKey(keyCode)
        }
    }, [emailAddress, keyCode])

    return (
        <div className="claim-body">
            <img id="claim-logo" src={Logo} alt="magfitLogo" />
            <div className="claim-section">
                <Form>
                    {loginErr && (
                        <Alert className="alert alert-danger">
                            Wrong email or password!
                        </Alert>
                    )}
                    {gymLookupErr && (
                        <Alert className="alert alert-danger">
                            No Gym Associated With That Account
                        </Alert>
                    )}
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="email-icon-box">
                            <i className="bi bi-envelope"></i>
                        </InputGroup.Text>
                        <Form.Control
                            id="email-input"
                            type="email"
                            placeholder="Email"
                            aria-label="email"
                            autoComplete="email"
                            aria-describedby="email-icon-box"
                            value={email}
                            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setEmail(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="password-icon-box">
                            <i className="bi bi-key"></i>
                        </InputGroup.Text>
                        <Form.Control
                            id="claim-input"
                            type='text'
                            placeholder="Claim Key"
                            aria-label="claim key"
                            value={key}
                            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setKey(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text className="currency-style" id="password-icon-box">
                            <i className="bi bi-lock"></i>
                        </InputGroup.Text>
                        <Form.Control
                            required
                            id="password-input"
                            type={hidePW ? 'password' : 'text'}
                            placeholder="Create a Password"
                            aria-label="password"
                            aria-describedby="password-icon-box"
                            autoComplete="true"
                            value={password}
                            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPassword(e.target.value)}
                        />
                        <InputGroup.Text
                            onClick={() => setHidePW(!hidePW)}
                            id="password-icon-box"
                            className="currency-style"
                        >
                            {!hidePW && <i className="bi bi-eye"></i>}
                            {hidePW && <i className="bi bi-eye-slash"></i>}
                        </InputGroup.Text>
                    </InputGroup>
                    {passwordErr && (
                        <Alert
                            className="mb-3"
                            variant="danger"
                        >You need a more secure password!<br />
                            <ul>
                                <li>Must be at least 6 characters.</li>
                                <li>Must contain a number</li>
                                <li>Must contain upper and lower case letters</li>
                                <li>Must include a special character: @, #, !, ~, $, ?, %, +, &, *</li>
                            </ul>
                        </Alert>
                    )}

                    <button className='custom-button' onClick={handleClaim} >Claim My Gym</button>
                </Form>
                <hr></hr>
                <small>
                    Dont have claim key? <Link to="/signup">Sign Up</Link>
                </small>
            </div>
        </div>

    )
}
