import React, { useState, useEffect } from 'react'
import { PageTemplate } from '../../PageTemplate'

import { AddClassModal } from './AddClassModal'
import { ClassModal } from './ClassModal'
import { useStoreContext } from '../../../Utilities/globalState'
import './styles.scss'
import { collection, getDocs, query, where } from '@firebase/firestore'
import { db } from '../../../firebase-config'
import { updateMemberClassAttendance } from '../../../Utilities/helpers'
import { CLASS_REF } from '../../../Utilities/dbRefs'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


export const Schedule = () => {
  const [state,] = useStoreContext()
  const [classWeek, setClassWeek] = useState(0)
  const [unfilteredClasses, setUnfilteredClasses] = useState<(any | null)[]>([])
  const [classes, setClasses] = useState<any[]>([
    {
      day: 'Monday',
      date: 0,
      classList: [],
    },

    {
      day: 'Tuesday',
      date: 0,
      classList: [],
    },

    {
      day: 'Wednesday',
      date: 0,
      classList: [],
    },

    {
      day: 'Thursday',
      date: 0,
      classList: [],
    },

    {
      day: 'Friday',
      date: 0,
      classList: [],
    },

    {
      day: 'Saturday',
      date: 0,
      classList: [],
    },

    {
      day: 'Sunday',
      date: 0,
      classList: [],
    },
  ])
  const [showAddClassModal, setShowAddClassModal] = useState(false)
  const [showClassModal, setShowClassModal] = useState(false)
  const [classDataIndex, setClassDataIndex] = useState<(any | null)[]>([])
  const [modalToShow, setModalToShow] = useState(7)
  const [updatedState, setUpdatedState] = useState(0)

  const update = () => setUpdatedState(updatedState + 1)

  const addClassModal = (index: number) => {
    setShowAddClassModal(true)
    setModalToShow(index)
  }

  const loadClasses = async () => {
    const classQuery = query(
      collection(db, CLASS_REF),
      where('gymID', '==', state.currentUser.gymId),
    )
    const classList: any[] = []
    const classListSnapshot = await getDocs(classQuery)
    classListSnapshot.forEach((doc) => {
      classList.push({ ...doc.data(), id: doc.id })
    })
    updateMemberClassAttendance(classList)
    setUnfilteredClasses([...classList])
  }

  const filterWeekClasses = (week: number) => {
    const filteredClasses = []
    const classesCopy = classes
    const monday = new Date()
    monday.setDate(monday.getDate() - ((monday.getDay() + 6) % 7) + 7 * week)
    monday.setHours(0, 0, 0, 0)
    const sunday = new Date()
    sunday.setDate(sunday.getDate() - (sunday.getDay() || 7) + 7 + 7 * week)
    sunday.setHours(23, 59, 59, 0)

    // filter classes between monday and sunday
    for (let session of unfilteredClasses) {
      let classTime = session.classDate.seconds * 1000
      if (classTime > monday.valueOf() && classTime < sunday.valueOf()) {
        filteredClasses.push(session)
      }
    }

    // set date headers for daily schedule
    for (let i = 1; i <= 7; i++) {
      classesCopy[i - 1].classList = []
      let date = new Date()
      date.setDate(date.getDate() - (date.getDay() || 7) + i + 7 * week)
      classesCopy[i - 1].date = date.getDate()
    }
    // fill class copy with class list data
    for (let session of filteredClasses) {
      let classTime = new Date(session.classDate.seconds * 1000)
      const classIndex = (classTime.getDay() - 1 + 7) % 7
      classesCopy[classIndex].classList = [
        ...classesCopy[classIndex].classList,
        session,
      ]
      classesCopy[classIndex].classList.sort(
        (a: any, b: any) => a.classDate - b.classDate,
      )
    }
    setClasses([...classesCopy])
  }

  const handleWeekSelection = (offset: number) => {
    setClassWeek(classWeek + offset)
    filterWeekClasses(classWeek)
  }

  const parseDate = (timeStamp: any) => {
    const date = new Date(timeStamp.seconds * 1000)
    return `
    ${date.getHours() % 12 || 12}:
    ${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}
    ${date.getHours() >= 12 ? 'pm' : 'am'}`
  }
  const getMonthName = (date: Date) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getMonth()];
  };

  const getCurrentMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    currentDate.setDate(currentDate.getDate() - (currentDate.getDay() || 7) + 7 * classWeek);
    const month = getMonthName(currentDate);
    return `${month}`;
  };
  const getCurrentYear = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    currentDate.setDate(currentDate.getDate() - (currentDate.getDay() || 7) + 7 * classWeek);
    return `${year}`;
  };

  useEffect(() => {
    filterWeekClasses(classWeek)
    // eslint-disable-next-line
  }, [unfilteredClasses, classWeek])

  useEffect(() => {
    loadClasses()
    // eslint-disable-next-line
  }, [state.currentUser.classList, updatedState])

  return (
    <PageTemplate>
      <div className="schedule-body">
        <div className='schedule-header'>
          <h2>Class Schedule</h2>
          <p>Let customers know the classes you offer.</p>
        </div>
        <div className='divider'></div>
        <div className='current-date'>
          <p className='current-month'>{getCurrentMonth()} <span className='current-year'>{getCurrentYear()}</span></p>
          <div className='group'>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="prev-week-tooltip">Previous Week</Tooltip>}
            >
              <button className="week-button" onClick={() => handleWeekSelection(-1)}>
                <i className="bi bi-chevron-left"></i>
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="next-week-tooltip">Next Week</Tooltip>}
            >
              <button className="week-button" onClick={() => handleWeekSelection(1)}>
                <i className="bi bi-chevron-right"></i>
              </button>
            </OverlayTrigger>

          </div>
        </div>

        <div className="schedule-container">
          {classes?.map((day, i) => {
            return (
              <div key={`shedule-day-${i}`} className="schedule-day">
                <div className='header'>
                  <p>{day.day} <span>{day.date}</span></p>
                  <button className="custom-button-fourth" onClick={() => addClassModal(i)}>Add Class</button>
                </div>
                <div className="classes-container">
                  {day.classList?.map((daysClass: any, j: number) => {
                    const trainer = state.gymMembers.filter(
                      (mem: any) => mem.id === daysClass.trainerId,
                    )
                    return (
                      <div
                        key={`days-class-${j}`}
                        className="class-body"
                        onClick={() => {
                          setClassDataIndex([i, j])
                          setShowClassModal(true)
                        }}
                      >
                        <div className='content'>
                          {/* {trainer[0]?.photo ? (
                            <div className="trainer-photo">
                              <img
                                className="trainer-photo"
                                src={trainer[0].photo}
                                alt=""
                              />
                            </div>
                          ) : (
                            <Avatar size={60} icon={<UserOutlined />} />

                          )} */}
                          <div className='class-data'>
                            <div className='class-header'>
                              <h3>{daysClass.class}</h3>
                              <i className="bi bi-three-dots"></i>
                            </div>


                            <div className='class-row'>
                              <div className='icon-item item-trainer'>
                                <i className="bi bi-person"></i>
                                <p>{daysClass.trainer}</p>
                              </div>
                              <p className='spacer'>•</p>
                              <div className='icon-item item-class-date'>
                                <i className="bi bi-clock"></i>
                                <p>{parseDate(daysClass.classDate)}</p>
                              </div>
                              <p className='spacer'>•</p>
                              <div className='icon-item item-duration'>
                                <i className="bi bi-hourglass-split"></i>
                                <p>{daysClass.duration}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <AddClassModal
                    day={day.day}
                    show={showAddClassModal}
                    setShow={setShowAddClassModal}
                    modalToShow={modalToShow}
                    index={i}
                    update={update}
                  />
                </div>
              </div>
            )
          })}
          <ClassModal
            show={showClassModal}
            setShow={setShowClassModal}
            data={classes}
            indexToShow={classDataIndex}
            update={update}
          />
        </div>
      </div>
    </PageTemplate>
  )
}