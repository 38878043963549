import React, { useState, useEffect } from 'react'
import { Modal, Alert, Form, FloatingLabel } from 'react-bootstrap'
import {
  doc,
  deleteDoc,
  setDoc,
  query,
  collection,
  where,
  getDocs,
} from 'firebase/firestore'
import { db } from '../../../../firebase-config'
import { useStoreContext } from '../../../../Utilities/globalState'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import { CLASS_REF } from '../../../../Utilities/dbRefs'
import { Badge, Descriptions } from 'antd';

export const ClassModal = ({
  show,
  setShow,
  data,
  indexToShow,
  update,
}: any) => {
  const [state, dispatch] = useStoreContext()
  const [editMode, setEditMode] = useState(false)
  const [cancelAlert, setCancelAlert] = useState(false)
  const [showMembers, setShowMembers] = useState(false)
  const [filteredMembers, setFilteredMembers] = useState<any[]>([])
  const [day, session] = indexToShow
  const [classData, setClassData] = useState(
    data[day]?.classList[session] || null,
  )
  const date = new Date(classData?.classDate.seconds * 1000) || null
  const membersInClass = new Set()
  const history = useNavigate()

  const handleClassUpdate = async () => {
    const classRef = doc(db, CLASS_REF, classData.id)
    setDoc(classRef, classData, { merge: true })
      .then(() => setEditMode(false))
      .then(() => setCancelAlert(false))
      .then(() => update())
  }

  const handleAddAtendee = (member: any) => {
    let classDataCopy = classData
    classDataCopy.attendeeIDs.push(member.id)
    classDataCopy.attendees.push(member)
    setClassData({ ...classDataCopy })
    const classRef = doc(db, CLASS_REF, classData.id)
    setDoc(classRef, classDataCopy, { merge: true })
  }

  const handleRemoveAttendee = (member: any) => {
    let classDataCopy = classData
    classDataCopy.attendeeIDs = classDataCopy.attendeeIDs.filter(
      (id: string) => id !== member.id,
    )
    classDataCopy.attendees = classDataCopy.attendees.filter(
      (attendee: any) => attendee.id !== member.id,
    )
    setClassData({ ...classDataCopy })
    const classRef = doc(db, CLASS_REF, classData.id)
    setDoc(classRef, classDataCopy, { merge: true })
  }

  const handleDeleteClass = async () => {
    await deleteDoc(doc(db, CLASS_REF, classData.id))

    let classListCopy = state.currentUser.classList
    classListCopy = classListCopy.filter((id: string) => id !== classData.id)
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        classList: classListCopy,
      },
    })
    setEditMode(false)
    setCancelAlert(false)
    setShow(false)
  }

  const handleDeleteRecurring = async () => {
    const classQuery = query(
      collection(db, CLASS_REF),
      where('recurrID', '==', classData.recurrID),
      where('classDate', '>', new Date()),
    )
    const classList: any[] = []
    const classListSnapshot = await getDocs(classQuery)
    classListSnapshot.forEach((doc) => {
      classList.push(doc.id)
    })
    let classListCopy = state.currentUser.classList

    for (let id of classList) {
      await deleteDoc(doc(db, CLASS_REF, id))
      // eslint-disable-next-line
      classListCopy = classListCopy.filter((id: string) => id !== id)
    }
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        classList: classListCopy,
      },
    })
    setEditMode(false)
    setCancelAlert(false)
    setShow(false)
  }

  const hideModal = () => {
    setShow(false)
    update()
  }

  useEffect(() => {
    if (classData?.attendeeIDs) {
      let membersCopy: any[] = []
      for (let id of classData.attendeeIDs) membersInClass.add(id)
      membersInClass.add(classData.trainerId)
      membersCopy = state.gymMembers.filter(
        (member: any) => !membersInClass.has(member.id),
      )
      setFilteredMembers([...membersCopy])
    }
    // eslint-disable-next-line
  }, [classData])

  useEffect(() => {
    data[day]?.classList[session] &&
      setClassData({ ...data[day]?.classList[session] })
    // eslint-disable-next-line
  }, [data[day]?.classList[session]])

  return (
    classData && (
      <Modal show={show} onHide={hideModal}>
        <div className="class-data-body">
          {!editMode && (
            <>
              <div className="class-title">
                <h3>
                  {classData.class}{' '}
                  {classData.trainer.length && classData.trainer !== 'None'
                    ? 'with'
                    : ''}{' '}
                  {classData.trainer !== 'None' && classData.trainer}
                </h3>
                {classData.recurring && (
                  <span>
                    <i
                      aria-label="Recurring Class"
                      className="bi bi-arrow-repeat"
                    ></i>
                  </span>
                )}
              </div>
              <h5>
                {date.getMonth() + 1}
                {' / '}
                {date.getDate()}
                {' / '}
                {date.getFullYear()}
                {' @ '} {date.getHours() % 12 || 12}:
                {(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}
                {date.getHours() >= 12 ? 'PM' : 'AM'}
              </h5>
              <h5>Duration: {classData.duration}</h5>
              <h5
                className={
                  classData.attendees.length >= classData.maxAttendees
                    ? 'alert-max'
                    : ''
                }
              >
                {/* Attendees: {classData.attendees.length} /{' '}
                {classData.maxAttendees} */}
                Max attendees: {classData.maxAttendees}

              </h5>
            </>
          )}
          {editMode && (
            <>
              <div className="class-title">
                <h3>Edit Class</h3>
              </div>
              <FloatingLabel label="Class Type">
                <Form.Control
                  className="add-class-input"
                  type="text"
                  placeholder="Class Type"
                  value={classData.class}
                  onChange={(e: { target: { value: any } }) =>
                    setClassData({
                      ...classData,
                      class: e.target.value,
                    })
                  }
                ></Form.Control>
              </FloatingLabel>
              <FloatingLabel label="Trainer">
                <Form.Select
                  className="add-class-input"
                  // type="text"
                  value={classData.trainer}
                  onChange={(e: { target: { selectedIndex: any; children: { [x: string]: any }; value: any } }) => {
                    const index = e.target.selectedIndex
                    const el = e.target.children[index]
                    const option = el.getAttribute('id')
                    setClassData({
                      ...classData,
                      trainer: e.target.value,
                      trainerId: option ? option : '',
                    })
                  }}
                >
                  <option value="None">None</option>
                  {state.gymMembers.map((member: any, i: number) => {
                    if (member.trainer)
                      return (
                        <option
                          id={`${member.id}`}
                          key={`member-option-${i}`}
                          value={`${member.fName}`}
                        >{`${member.fName} ${member.lName}`}</option>
                      )
                    else return null
                  })}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel label="Max Capacity">
                <Form.Control
                  className="add-class-input"
                  type="number"
                  value={classData?.maxAttendees}
                  onChange={(e: { target: { value: any } }) =>
                    setClassData({
                      ...classData,
                      maxAttendees: Number(e.target.value),
                    })
                  }
                ></Form.Control>
              </FloatingLabel>
              <FloatingLabel label="Time">
                <Form.Control
                  className="add-class-input"
                  type="time"
                  value={classData.time}
                  onChange={(e: { target: { value: any } }) =>
                    setClassData({
                      ...classData,
                      time: e.target.value,
                    })
                  }
                ></Form.Control>
              </FloatingLabel>
              <FloatingLabel label="Duration">
                <Form.Select
                  className="add-class-input"
                  value={classData.duration}
                  onChange={(e: { target: { value: any } }) =>
                    setClassData({
                      ...classData,
                      duration: e.target.value,
                    })
                  }
                >
                  <option>Open this select menu</option>
                  <option value="0:30">0:30</option>
                  <option value="1:00">1:00</option>
                  <option value="1:30">1:30</option>
                  <option value="2:00">2:00</option>
                  <option value="2:30">2:30</option>
                  <option value="3:00">3:00</option>
                </Form.Select>
              </FloatingLabel>
            </>
          )}
          {!editMode ? (
            <div className="class-section">
              {/* <div className="attendee-section">
            {!classData.attendees.length ? (
              <div className="attendee-body ">
                <h6>No Attendees currently in this session</h6>
              </div>
            ) : (
              classData.attendees.map((member: any, i: number) => {
                return (
                  <div key={`attendees-${i}`} className="attendee-body">
                    <h6>
                      {member.fName} {member.lName}
                    </h6>
                    <i
                      onClick={() => handleRemoveAttendee(member)}
                      className="bi bi-x-square"
                    ></i>
                  </div>
                )
              })
            )}
          </div> */}

              {classData.attendees.length < classData.maxAttendees &&
                showMembers && (
                  <div className="members-section">
                    {filteredMembers.map((member: any, i: number) => {
                      return (
                        <div
                          key={`attendee-option-${i}`}
                          className="attendee-body not-added"
                          onClick={() => handleAddAtendee(member)}
                        >
                          <h6>
                            {member.fName} {member.lName}
                          </h6>
                          <i className="bi bi-plus-square"></i>
                        </div>
                      )
                    })}
                    {!filteredMembers.length && (
                      <div
                        className="attendee-body not-added"
                        onClick={() => history(`/members`)}
                      >
                        <h6>
                          No Members available. Please add a member on the
                          "Members" page!
                        </h6>
                      </div>
                    )}
                  </div>
                )}
            </div>

          ) : (
            <>
            </>
          )}

          <div className="class-footer">
            {editMode ? (
              <button
                className="custom-button-third"
                onClick={() => setCancelAlert(!cancelAlert)}
              >
                {cancelAlert ? 'KEEP THE CLASS' : 'CANCEL CLASS'}
              </button>
            ) : (
              <>
                {/* {classData.attendees.length < classData.maxAttendees && (
                  <button
                    className="custom-button class-button"
                    onClick={() => setShowMembers(!showMembers)}
                  >
                    {showMembers ? (
                      <>
                        DONE <i className="bi bi-plus-square"></i>
                      </>
                    ) : (
                      <>
                        ADD <i className="bi bi-plus-square"></i>
                      </>
                    )}
                  </button>
                )} */}
              </>
            )}
            {editMode ? (
              <button className="custom-button" onClick={handleClassUpdate}>
                SAVE <i className="bi bi-save"></i>
              </button>
            ) : (
              <button
                className="custom-button-second"
                onClick={() => setEditMode(!editMode)}
              >
                EDIT <i className="bi bi-pencil"></i>
              </button>
            )}
          </div>
          {cancelAlert && (
            <Alert variant="danger">
              Are you sure you want to remove this class? All users will be
              removed from the booking and notified. <br />
              <br />
              To remove just this session: <br />
              <button
                className="custom-button class-button delete"
                onClick={handleDeleteClass}
              >
                REMOVE
              </button>
              <br />
              {classData.recurring && (
                <>
                  {' '}
                  To remove all recurring sessions: <br />
                  <button
                    className="custom-button class-button delete"
                    onClick={handleDeleteRecurring}
                  >
                    REMOVE ALL
                  </button>
                </>
              )}
            </Alert>
          )}
        </div>
      </Modal>
    )
  )
}