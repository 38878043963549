// Import the functions you need from the SDKs you need
import {
  initializeApp
} from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator
} from "firebase/firestore";
import {
  getStorage,
  connectStorageEmulator
} from "firebase/storage"
import {
  getAuth,
  GoogleAuthProvider,
  connectAuthEmulator
} from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator
} from 'firebase/functions'

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
  apiKey: "AIzaSyAUO8VzuM1hEJZq24EBRunK7E53B9zJfuQ",
  authDomain: "magfit-inc.firebaseapp.com",
  databaseURL: "https://magfit-inc-default-rtdb.firebaseio.com",
  projectId: "magfit-inc",
  storageBucket: "magfit-inc.appspot.com",
  messagingSenderId: "393013899059",
  appId: "1:393013899059:web:fe89e813aa66bad8029846",
  measurementId: "G-8MQXGKV5LC"
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app)
const auth = getAuth(app);
const provider = new GoogleAuthProvider();



if (window.location.hostname !== "dashboard.moregyms.com") {
  const host = window.location.hostname;
  console.log(`---local database---${host}---`);

  connectFunctionsEmulator(functions, host, 5001);
  connectFirestoreEmulator(db, host, 8080);
  connectStorageEmulator(storage, host, 9199);
  connectAuthEmulator(auth, `http://${host}:9099`);
}

export {
  app,
  functions,
  db,
  storage,
  auth,
  provider,
}