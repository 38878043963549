import React, { useState, useEffect } from 'react'
import { functions } from '../../../../firebase-config'
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { useStoreContext } from '../../../../Utilities/globalState';
import { useNavigate, useBlocker } from 'react-router-dom';
import "./styles.scss"
import NavigationPrompt from '../../../NavigationPrompt';
import { doc, setDoc, getDoc } from '@firebase/firestore';
import { db } from '../../../../firebase-config';
import { Form, InputGroup, Spinner, Alert, FloatingLabel } from 'react-bootstrap'


export default function PaymentSettings() {
  const [state, dispatch] = useStoreContext();
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [stripeMessage, setStripeMessage] = useState('');
  const [paymentPackages, setPaymentPackages] = useState<any[]>([
    {
      itemName: "Daily",
      itemPrice: 0.00,
      enabled: false,
    },
    {
      itemName: "Weekly",
      itemPrice: 0.00,
      enabled: false,
    },
    {
      itemName: "Monthly",
      itemPrice: 0.00,
      enabled: false,
    },
    {
      itemName: "Bi-Annual",
      itemPrice: 0.00,
      enabled: false,
    },
    {
      itemName: "Annual",
      itemPrice: 0.00,
      enabled: false,
    },
  ]);
  const [changesMade, setChangesMade] = useState(false);

  const createStripeAccount: HttpsCallable = httpsCallable(functions, 'createStripeAccount')
  const getStripeAccount: HttpsCallable = httpsCallable(functions, 'createStripeAccountLink')

  function handleStripeCreateAccount() {
    setLoading(true);
    createStripeAccount()
      .then((response: any) => {
        if (response.data) {
          setAccount(response.data);
          dispatch({
            type: 'SET_CURRENT_USER',
            currentUser: {
              ...state.currentUser,
              stripeData: response.data,
            },
          })
        }
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      })
  }

  function handleGetStripeAccount() {
    if (!state.currentUser?.stripeData?.id) return;
    getStripeAccount({ accountID: state.currentUser.stripeData.id })
      .then((response: any) => {
        const { data } = response;
        // console.log(data);
        window.location = data.url;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleSaveChangesToPackages() {
    setChangesMade(false);
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        paymentPackages: paymentPackages,
      },
    });
    console.log('Changes saved');
  }

  useEffect(() => {
    if (!state.currentUser?.stripeData?.id || !loading) return;
    setLoading(false);
    handleGetStripeAccount();
  }, [state, loading]);

  useEffect(() => {
    if (!state.currentUser.paymentPackages) return;
    if (!state.currentUser.paymentPackages.length) return;
    setPaymentPackages(state.currentUser.paymentPackages);
  }, [state]);

  useEffect(() => {
    if (!state.currentUser.stripeData) {
      setStripeMessage('Connect your gym to Stripe to start accepting payments.');
    } else if (!state.currentUser.stripeData.details_submitted) {
      setStripeMessage('Complete your account details to start accepting payments.');
    } else if (state.currentUser.stripeData.requirements.currently_due.length || state.currentUser.stripeData.requirements.past_due.length) {
      setStripeMessage('Stripe needs more information to complete your account details, please update your account details.');
    } else {
      setStripeMessage('Your account details have been submitted and are up to date.');
    }
  }, [state.currentUser.stripeData]);


  return (
    <>
      <NavigationPrompt
        when={changesMade}
        setWhen={setChangesMade}
        message="You have unsaved changes, are you sure you want to leave?"
        save={handleSaveChangesToPackages}
      />

      <div className="payment-settings-body">
        <section className='stripe-section'>
          <div className='stripe-heading'>
            <h2>Payment Settings</h2>
            <p>{stripeMessage}</p>
          </div>

          <div className="stripe-settings">
            {!state.currentUser.stripeData && <div className="stripe-settings__section">
              <button className='stripe-button' onClick={handleStripeCreateAccount}>Connect to Stripe</button>
            </div>}
            {state.currentUser.stripeData && <div className="stripe-settings__section">
              {!state.currentUser.stripeData.details_submitted && <div>
                <button className='stripe-button' onClick={handleGetStripeAccount} >Complete Account</button>
              </div>}
              {state.currentUser.stripeData.details_submitted && <div>
                <button className='stripe-button' onClick={handleGetStripeAccount} >Update Account</button>
              </div>}

            </div>}
          </div>
        </section>
        <hr />
        <section className='subscription-section'>
          <h2>Prices</h2>
          <div className="subscription-packages">
            <div className='subscription-heading'>
              <p>Packages can be used for one time or recurring payment subscriptions.</p>
              <button className="custom-button" disabled={!changesMade} onClick={handleSaveChangesToPackages}>Save</button>
            </div>
            {paymentPackages.map((item, index) => {
              return (
                <div className="subscription-package" key={index + "00000"}>
                  <div className="package-header">
                    <h4>{item.itemName}</h4>
                    <span className="enable-span">
                      <label className="custom-switch">
                        <input
                          onChange={(e) => {
                            const newPackages = [...paymentPackages];
                            newPackages[index].enabled = e.target.checked;
                            setChangesMade(true);
                            setPaymentPackages(newPackages);
                          }}
                          type="checkbox"
                          checked={item.enabled}
                        ></input>
                        <span className="custom-switch-slider"></span>
                      </label>
                      {!item.enabled ? (
                        <label htmlFor={`enable-${item.itemName.toLowerCase()}`} className='activate'>Activate</label>
                      ) : (
                        <span className='activated'>Activated</span>
                      )}
                    </span>
                  </div>
                  <div className="package-body">
                    <InputGroup className="mb-3 currency-input">
                      <InputGroup.Text className="currency-style" id="currency-icon-box">
                        <i className="bi bi-currency-dollar" />
                      </InputGroup.Text>
                      <FloatingLabel controlId={`${item.itemName.toLowerCase()}-price`} label={`${item.itemName} Price`}>
                        <Form.Control
                          type="number"
                          name={`${item.itemName.toLowerCase()}-price`}
                          min="0.00"
                          step="0.5"
                          max="2500"
                          onChange={(e) => {
                            const newPackages = [...paymentPackages];
                            newPackages[index].itemPrice = Number(e.target.value);
                            setChangesMade(true);
                            setPaymentPackages(newPackages);
                          }}
                          value={Number(item.itemPrice)}
                        />
                      </FloatingLabel>
                    </InputGroup>
                  </div>

                </div>
              )
            })}
          </div>
        </section>
      </div>
    </>
  )
}
