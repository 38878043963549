import React, { useEffect } from 'react'
import './App.scss'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet
} from 'react-router-dom'
import { useStoreContext } from './Utilities/globalState'
import { Nav } from './Components/Nav/index'
// import { Amenities } from './Components/GymPages/InfoPages/Amenities'
// import { Hours } from './Components/GymPages/Hours'
// import { Info } from './Components/GymPages/InfoPages/Info'
// import { Services } from './Components/GymPages/InfoPages/Services'
import { Settings } from './Components/GymPages/Settings'
import { Login } from './Components/LoginPages/Login'
import { PassKey } from './Components/LoginPages/Passkey'
import { SignupCarousel } from './Components/LoginPages/SignupCarousel'
import { Schedule } from './Components/GymPages/Schedule'
import { Media } from './Components/GymPages/Media'
import { Reviews } from './Components/GymPages/Reviews'
import { Members } from './Components/GymPages/Members'
import { InfoPages } from './Components/GymPages/InfoPages'
import { Home } from './Components/GymPages/Home'
import { ForgotPassword } from "./Components/LoginPages/ForgotPassword"
import Store from "./Components/GymPages/Store"
import ClaimGym from "./Components/LoginPages/ClaimGym"
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./Components/Utilities/ErrorPage";



const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <Outlet />
  </ErrorBoundary>
);

function App() {
  const [state] = useStoreContext()

  useEffect(() => {
    localStorage.setItem('magfitState', JSON.stringify(state))
  }, [state])


  const router = createBrowserRouter([
    {
      element: <ErrorBoundaryLayout/>,
      children:[
        {
          path: "/forgotpassword",
          element: state.loggedIn ? <Navigate replace to="/schedule" /> : <><ForgotPassword /></>
        },
        {
          path: "/schedule",
          element: state.loggedIn ? <><Nav /><Schedule /></> : <Navigate replace to="/login" />
        },
        {
          path: "/members",
          element: state.loggedIn ? <><Nav /><Members /></> : <Navigate replace to="/login" />
        },
        {
          path: "/media",
          element: state.loggedIn ? <><Nav /><Media /></> : <Navigate replace to="/login" />
        },
        {
          path: "/reviews",
          element: state.loggedIn ? <><Nav /><Reviews /></> : <Navigate replace to="/login" />
        },
        {
          path: "/home",
          element: state.loggedIn ? <><Nav /><Home /></> : <Navigate replace to="/login" />
        },
        {
          path: "/settings/:paymentIntentID",
          element: state.loggedIn ? <><Nav /><Settings /></> : <Navigate replace to="/login" />
        },
        {
          path: "/settings",
          element: state.loggedIn ? <><Nav /><Settings /></> : <Navigate replace to="/login" />
        },
        {
          path: "/info",
          element: state.loggedIn ? <><Nav /><InfoPages /></> : <Navigate replace to="/login" />
        },
        {
          path: "/store",
          element: state.loggedIn ? <><Nav /><Store /></> : <Navigate replace to="/login" />
        },
        {
          path: "/login",
          element: <Login />
        },
        {
          path: "/claim",
          element: <ClaimGym />
        },
        {
          path: "/claim/:emailAddress",
          element: <ClaimGym />
        },
        {
          path: "/claim/:emailAddress/:keyCode",
          element: <ClaimGym />
        },
        {
          path: "/passkey",
          element: <PassKey />
        },
        {
          path: "/signup",
          element: <SignupCarousel />
        },
        {
          path: "/signup/:emailAddress",
          element: <SignupCarousel />
        },
        {
          path: "*",
          element: state.loggedIn ? <><Nav /><Home /></> : <Navigate replace to="/login" />
        }
      ]
    }
  ])

  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  )
}

export default App
