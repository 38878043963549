import React, { useEffect, useState, useRef } from 'react'
import './style.scss'
import { useStoreContext } from '../../../../Utilities/globalState'
import { doc, getDoc, } from 'firebase/firestore'
import { db } from "../../../../firebase-config"
import { parseDataForGraph } from '../../../../Utilities/helpers';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { functions } from '../../../../firebase-config'
import { reportNonBlockingError } from '../../../../Utilities/helpers';
import { Link } from 'react-router-dom'
import { Empty, Table, Tag } from 'antd';


interface StripeBalance {
    available: Array<{ amount: number, currency: string }>;
    pending: Array<{ amount: number, currency: string }>;
}
interface StripePayout {
    amount: number;
    currency: string;
}

export default function Numbers() {
    const [state, dispatch] = useStoreContext()
    const id = state.currentUser?.gymId;
    const chartRef = React.useRef<any>(null);
    const [mobileGraphData, setMobileGraphData] = React.useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [webGraphData, setWebGraphData] = React.useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [stripeBalance, setStripeBalance] = useState<StripeBalance | null>(null);
    const [stripePayouts, setStripePayouts] = useState<StripePayout[]>([]);

    const [stripeSessionToTrack, setStripeSessionToTrack] = useState('')
    const [stripeSessions, setStripeSessions] = useState<any[]>([])
    const [purchaseComplete, setPurchaseComplete] = useState(false);
    const [stripeError, setStripeError] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    async function getFirebaseData() {
        if (!id) return;
        const dataRef = doc(db, "GymMetrics", id);
        const dataSnap = await getDoc(dataRef);

        if (dataSnap.exists()) {
            const data = dataSnap.data()
            const updatedMobileData = await parseDataForGraph(data, "iOS App")
            const updatedWebData = await parseDataForGraph(data, "Web App")
            setMobileGraphData(updatedMobileData)
            setWebGraphData(updatedWebData)
        }
    }

    function updateStripeSessions() {
        if (!state.currentUser?.stripeData?.id) return;
        setLoading(true);
        const getAllStripeSessions: HttpsCallable = httpsCallable(functions, 'getAllStripeSessions')
        getAllStripeSessions({ id: state.currentUser.stripeData.id })
            .then((response: any) => {
                if (response.data) {
                    setStripeSessions(response.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        async function fetchStripeBalance() {
            if (state.currentUser?.stripeData?.id) {
                const fetchStripeBalance = httpsCallable(functions, 'retrieveBalance');
                try {
                    const result = await fetchStripeBalance({ id: state.currentUser.stripeData.id });
                    setStripeBalance(result.data as StripeBalance);
                } catch (error) {
                    console.error("Error fetching Stripe balance:", error);
                    setStripeError(true);
                }
            }
        }

        fetchStripeBalance();
    }, [state.currentUser?.stripeData?.id]);


    useEffect(() => {
        async function fetchAllPayouts() {
            if (state.currentUser?.stripeData?.id) {
                const fetchAllPayouts = httpsCallable(functions, 'payoutsList');

                try {
                    const result = await fetchAllPayouts({ id: state.currentUser.stripeData.id });
                    console.log("Payouts Result:", result.data); // Log the data to inspect

                    // Check and map the payouts data if it exists and is an array
                    if (result.data && Array.isArray((result.data as any).data)) {
                        const payoutsData: StripePayout[] = (result.data as any).data.map((payout: any) => ({
                            amount: payout.amount,
                            currency: payout.currency,
                        }));
                        setStripePayouts(payoutsData);
                    } else {
                        setStripePayouts([]);
                    }
                } catch (error) {
                    console.error("Error fetching Stripe payouts:", error);
                    setStripeError(true);
                }
            }
        }

        fetchAllPayouts();
    }, [state.currentUser?.stripeData?.id]);

    useEffect(() => {
        if (!stripeSessionToTrack.length) return;
        const sessionInterval = setInterval(() => {
            if (!state.currentUser?.stripeData?.id) return;
            const checkSessionStatus: HttpsCallable = httpsCallable(functions, 'checkSessionStatus')
            checkSessionStatus({ sessionID: stripeSessionToTrack, id: state.currentUser.stripeData.id })
                .then((response: any) => {
                    if (response.data) {
                        if (response.data.status === 'complete') {
                            setStripeSessionToTrack('');
                            clearInterval(sessionInterval);
                            setPurchaseComplete(true);
                            updateStripeSessions();
                        }
                    }
                })
                .catch((error: any) => {
                    reportNonBlockingError(error, state, 'Store -> updateStripeSessions -> checkSessionStatus');
                    updateStripeSessions();
                })
        }, 7000)
        return () => clearInterval(sessionInterval);
    }, [stripeSessionToTrack, state.currentUser?.stripeData?.id])

    useEffect(() => {
        if (state.currentUser?.stripeData) {
            updateStripeSessions()
        }
    }, [state.currentUser?.stripeData]);

    useEffect(() => {
        if (state.currentUser?.stripeData) {
            const { requirements, charges_enabled, payouts_enabled, details_submitted } = state.currentUser.stripeData;
            if (requirements.currently_due.length ||
                requirements.past_due.length ||
                requirements.pending_verification.length ||
                requirements.errors.length ||
                !charges_enabled ||
                !payouts_enabled ||
                !details_submitted) {
                setStripeError(true);
            } else {
                setStripeError(false);
            }
        }
    }, [state.currentUser?.stripeData])

    function PaymentsDisplay() {
        const sortedSessions = stripeSessions.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });

        const latestFivePaidSessions = sortedSessions
            .filter(session => session.status === "succeeded" && session.charges?.data[0]?.paid)
            .slice(0, 5);


        const columns = [
            {
                title: 'Billing Name',
                dataIndex: 'billing_details',
                key: 'billing_details',
                render: (text: { name: any }) => text.name,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (text: number) => `$${(text / 100).toFixed(2)}`,
            },
            {
                title: 'Status',
                dataIndex: 'refunded',
                key: 'refunded',
                render: (text: any) => (
                    text ? <Tag color="red">REFUNDED</Tag> : <Tag color="green">PAID</Tag>
                ),
            },
            {
                title: 'Date',
                dataIndex: 'created',
                key: 'created',
                render: (text: number) => new Date(text * 1000).toDateString(),
            },
        ];

        const dataSource = latestFivePaidSessions.map((session, index) => ({
            key: index,
            created: session.created,
            amount: session.amount,
            refunded: session.charges?.data[0]?.refunded,
            billing_details: session.charges?.data[0]?.billing_details,
            session,
        }));


        return (
            <div className="payment-home-body">
                <div className='transaction-heading'>
                    <div className='group'>
                        <h3>Latest Transactions</h3>
                        <p>Here are your most recent successful transactions.</p>
                    </div>
                    <Link className="custom-button-second" to="/store#payments">View all</Link>
                </div>
                <div className="payments">
                    {loading ? (
                        <div className="spinner-container">
                            <Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
                        </div>
                    ) : (
                        latestFivePaidSessions.length > 0 ? (
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                onRow={(record) => ({
                                    onClick: () => {
                                        setSelectedPayment(record.session);
                                        setShowPaymentModal(true);
                                    },
                                })}
                                pagination={false}
                            />
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No payment history</span>} />
                        )
                    )}
                </div>
            </div>
        );
    }
    function StripeNotSetUp() {

        return (
            <div className="payment-home-body">
                <div className='transaction-heading'>
                    <div className='group'>
                        <h3>Latest Transactions</h3>
                        <p>Keep track of your latest transactions.</p>
                    </div>
                    <Link className="custom-button-second" to="/settings#payment-settings">Connect to Stripe</Link>
                </div>
                <div className="payments">
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>Connect to Stripe to recieve payments</span>} />
                </div>
            </div>
        )
    }
    function AvailabaleBalance() {
        if (!stripeBalance) return <h3>$0.00</h3>;

        const availableBalance = stripeBalance.available.map((balance, index) => (
            <div key={index}>
                <h3>${(balance.amount / 100).toFixed(2)}</h3>
            </div>
        ));

        return (
            <div>{availableBalance}</div>
        );
    }
    function PendingBalance() {
        if (!stripeBalance) return <h3>$0.00</h3>;

        const pendingBalance = stripeBalance.pending.map((balance, index) => (
            <div key={index}>
                <h3>${(balance.amount / 100).toFixed(2)}</h3>
                {/* {balance.currency.toUpperCase()}  */}
            </div>
        ));

        return (
            <div>{pendingBalance}</div>
        );
    }
    function CalculateAverageEarningsPerVisit() {
        if (!stripeBalance) return <h3>$0.00</h3>;

        const totalPayouts = stripePayouts.reduce((acc, payout) => acc + payout.amount, 0);

        const availableBalance = stripeBalance.available
            .map((balance) => balance.amount)
            .reduce((acc, amount) => acc + amount, 0) || 0;
        const pendingBalance = stripeBalance.pending
            .map((balance) => balance.amount)
            .reduce((acc, amount) => acc + amount, 0) || 0;

        const totalEarnings = totalPayouts + availableBalance + pendingBalance;

        const averageEarningsPerVisit = totalClicks > 0 ? totalEarnings / totalClicks : 0;

        return (<h3>${(averageEarningsPerVisit / 100).toFixed(2)}</h3>);
    }



    function AllPayouts() {
        if (!stripePayouts) return null;

        const totalAmount = stripePayouts.reduce((total, payout) => total + payout.amount, 0);


        return (
            <div><h3>${(totalAmount / 100).toFixed(2)}</h3></div>
        );
    }
    useEffect(() => {
        getFirebaseData()
    }, [id])

    useEffect(() => {
        if (chartRef.current?.chartInstance) {
            chartRef.current.chartInstance.update();
        }
    }, [mobileGraphData, webGraphData])

    const totalClicks = mobileGraphData.reduce((acc, value) => acc + value, 0) + webGraphData.reduce((acc, value) => acc + value, 0);


    function CheckoutCompletionRate() {
        // Sort sessions by created date in descending order and take the latest 10
        const latestSessions = stripeSessions
            .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())

        // Filter paid sessions from the latest sessions
        const paidSessions = latestSessions.filter(session =>
            session.charges?.data[0]?.paid === true
        );

        const totalPaidSessions = paidSessions.length;
        const totalLatestSessions = latestSessions.length;

        const conversionRate = totalLatestSessions !== 0 ? (totalPaidSessions / totalLatestSessions) * 100 : 0;

        return (
            <h3>{conversionRate.toFixed(2)}%</h3>
        );
    }
    function AvgRevenuePerTransaction() {
        const paidSessions = stripeSessions.filter(session =>
            session.charges?.data[0]?.paid && !session.charges?.data[0]?.refunded
        );

        const totalAmount = paidSessions.reduce((total, session) => {
            return total + (session.amount / 100);
        }, 0);

        const averageRevenuePerTransaction = paidSessions.length > 0 ? totalAmount / paidSessions.length : 0;

        return (
            <h3>${averageRevenuePerTransaction.toFixed(2)}</h3>
        );
    }

    const profileVisitsInfoPopover = (
        <Popover id="profile-visits-popover">
            <Popover.Header as="h3">Profile Visits</Popover.Header>
            <Popover.Body>
                This number indicates the total views your page has received on MoreGyms.com and the MoreGyms app.
            </Popover.Body>
        </Popover>
    );

    const checkoutCompletionRate = (
        <Popover id="conversion-rate-popover">
            <Popover.Header as="h3">Checkout Completion Rate</Popover.Header>
            <Popover.Body>
                This indicates how many times a checkout has resulted in a completed transaction.
            </Popover.Body>
        </Popover>
    );

    const revenuePerClickInfoPopover = (
        <Popover id="revenue-per-visit-popover">
            <Popover.Header as="h3">Avg. Revenue Per Transaction</Popover.Header>
            <Popover.Body>
                This represents the average revenue generated per transaction based on the last 100 payments.
            </Popover.Body>
        </Popover>
    );

    const averageEarningsPerVisit = (
        <Popover id="revenue-per-visit-popover">
            <Popover.Header as="h3">Revenue</Popover.Header>
            <Popover.Body>
                This metric helps you understand how effectively your page converts visitors into revenue.
            </Popover.Body>
        </Popover>
    );

    const pendingBalanceInfoPopover = (
        <Popover id="revenue-per-visit-popover">
            <Popover.Header as="h3">Pending Balance</Popover.Header>
            <Popover.Body>
                The pending balance represents the funds currently held and not yet transferred to your account due to processing delays or possible disputes/chargebacks.
            </Popover.Body>
        </Popover>
    );

    const availableFundsInfoPopover = (
        <Popover id="revenue-per-visit-popover">
            <Popover.Header as="h3">Available Funds</Popover.Header>
            <Popover.Body>
                Available funds represent the current balance of funds available for withdrawal or transfer. Funds are sent directly to your bank account on the 1st of every month.
            </Popover.Body>
        </Popover>
    );

    const totalPayoutsInfoPopover = (
        <Popover id="revenue-per-visit-popover">
            <Popover.Header as="h3">Total Payouts</Popover.Header>
            <Popover.Body>
                Total payouts reflect the total revenue generated by your business, including fees, taxes, and refunds.
            </Popover.Body>
        </Popover>
    );

    return (
        <div className='numbers-body'>
            <div className='numbers-group'>
                <div className='number-container'>
                    <h3>{totalClicks}</h3>
                    <div className='description'>
                        <p>Total Profile Visits</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={profileVisitsInfoPopover}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='number-container'>
                    <PendingBalance />
                    <div className='description'>
                        <p>Pending Balance</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={pendingBalanceInfoPopover}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='number-container'>
                    <AvailabaleBalance />
                    <div className='description'>
                        <p>Available Funds</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={availableFundsInfoPopover}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='number-container'>
                    <AllPayouts />
                    <div className='description'>
                        <p>Total Payouts</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={totalPayoutsInfoPopover}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            <div className='numbers-group'>
                <div className='number-bottom-container'>
                    <CheckoutCompletionRate />
                    <div className='description'>
                        <p>Checkout Completion Rate</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={checkoutCompletionRate}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='number-bottom-container'>
                    <AvgRevenuePerTransaction />
                    <div className='description'>
                        <p>Avg. Revenue Per Transaction</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={revenuePerClickInfoPopover}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='number-bottom-container'>
                    <CalculateAverageEarningsPerVisit />
                    <div className='description'>
                        <p>Avg. Earnings Per Visit</p>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={averageEarningsPerVisit}>
                            <i className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            {state.currentUser?.stripeData && state.currentUser.stripeData.id ? (
                <PaymentsDisplay />
            ) : (
                // Replace this with the component or content you want to display if the condition is not met
                <StripeNotSetUp />
            )}
        </div>
    )
}
