import React, { useEffect, useRef, useState } from 'react';
import "./style.scss";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartTypeRegistry,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import { useStoreContext } from '../../../../Utilities/globalState';
import { parseDataForGraph } from '../../../../Utilities/helpers';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Page Views',
        },
        font: {
            size: 18,
            weight: 'bold',
        },
        
    },
    scales: {
        x: {
            grid: {
                display: true,
                color: 'rgba(0,0,0,0.1)',
                lineWidth: 0,
            },
            ticks: {
                font: {
                    size: 12,
                },
                color: 'black',
            },
        },
        y: {
            beginAtZero: true,
            grid: {
                display: true,
                color: 'rgba(0,0,0,0.1)',
                lineWidth: 0,
            },
        },
    },
    elements: {
        bar: {
            borderWidth: 2,
            borderRadius: 8,
        },
    },
};

export default function Graph() {
    const [state] = useStoreContext();
    const id = state.currentUser.gymId;
    const chartRef = useRef<ChartJS<"bar">>(null);
    const [loading, setLoading] = useState(true);
    const [graphLabels, setGraphLabels] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', "November", "December"]);
    const [mobileGraphData, setMobileGraphData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [webGraphData, setWebGraphData] = useState([0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [graphData, setGraphData] = useState({
        labels: updateLabels(),
        datasets: [
            {
                label: 'Mobile Views',
                data: mobileGraphData,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
            },
            {
                label: 'Web Views',
                data: webGraphData,
                backgroundColor: 'rgb(20, 20, 19, 0.5)',
                borderColor: 'rgb(20, 20, 19)',
                borderWidth: 1,
            },
        ],
    });

    async function getFirebaseData() {
        const dataRef = doc(db, "GymMetrics", id);
        const dataSnap = await getDoc(dataRef);

        if (dataSnap.exists()) {
            const data = dataSnap.data();
            const updatedMobileData = await parseDataForGraph(data, "iOS App");
            const updatedWebData = await parseDataForGraph(data, "Web App");
            setMobileGraphData(updatedMobileData);
            setWebGraphData(updatedWebData);
            setGraphData({
                labels: updateLabels(),
                datasets: [
                    {
                        label: 'Mobile Views',
                        data: updatedMobileData,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 1,
                    },
                    {
                        label: 'Web Views',
                        data: updatedWebData,
                        backgroundColor: 'rgb(20, 20, 19, 0.5)',
                        borderColor: 'rgb(20, 20, 19)',
                        borderWidth: 1,
                    },
                ],
            });
        } else {
            console.log("No graph data available");
        }
        setLoading(false);
    }

    function updateLabels() {
        const date = new Date();
        const month = date.toLocaleString('default', { month: 'long' });
        const updatedList = [...graphLabels];
        while (updatedList[updatedList.length - 1] !== month) {
            updatedList.push(updatedList.shift()!);
        }
        return updatedList;
    }

    useEffect(() => {
        getFirebaseData();
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.update();
        }
    }, [graphData, loading, mobileGraphData, webGraphData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='chart-body'>
            <h3>Page Analytics</h3>
            <p>Track how many people are viewing your page.</p>
            <div className='bar-chart'>
                <Bar options={options} data={graphData} ref={chartRef} />
            </div>
        </div>
    );
}
