import React, { useState, useEffect } from 'react'
import {
  FloatingLabel,
  InputGroup,
  Form,
  Spinner,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useStoreContext } from '../../../../Utilities/globalState'
import { doc, setDoc } from '@firebase/firestore'
import { db } from '../../../../firebase-config'
import {
  parseDataForDB,
  parseAmenityKeysForDisplay,
  reportNonBlockingError,
} from '../../../../Utilities/helpers'
import { GYMS_REF } from '../../../../Utilities/dbRefs'
import './styles.scss'

export const Services = () => {
  const [state, dispatch] = useStoreContext()
  const [trainersAvailable, setTrainersAvailable] = useState(state.currentUser.services.trainersAvailable || false)
  const [dropInPrice, setDropInPrice] = useState(
    state.currentUser.services.dropInPrice || 0,
  )
  const [dayPassLinks, setdayPassLinks] = useState(state.currentUser.dayPassLink?.length ? [...state.currentUser.dayPassLink, ""] : [""],
  )
  const [instagramUsername, setInstagramUsername] = useState(
    state.currentUser.instagramUsername || ''
  )
  const [wifiPW, setwifiPW] = useState(state.currentUser.services.wifiPW || '')
  const [wifiAvailable, setWifiAvailable] = useState(
    state.currentUser.services.wifiAvailable || false,
  )
  const [dayPass, setdayPass] = useState(
    state.currentUser.services.dayPass || false,
  )
  const [openGym, setopenGym] = useState(
    state.currentUser.services.openGym || false,
  )
  const [openGymInfo, setOpenGymInfo] = useState(
    state.currentUser.services.openGymInfo || '',
  )
  const [nutritionCoaching, setNutritionCoaching] = useState(
    state.currentUser.services.nutritionCoaching || false,
  )
  const [personalFilming, setPersonalFilming] = useState(
    state.currentUser.services.personalFilming || false,
  )
  const [classesOffered, setClassesOffered] = useState(
    state.currentUser.services.classesOffered || false,
  )
  const [classesOfferedInfo, setClassesOfferedInfo] = useState(
    state.currentUser.services.classesOfferedInfo || '',
  )
  const [weightPlatforms, setWeightPlatforms] = useState(
    state.currentUser.services.weightPlatforms || false,
  )
  const [parking, setParking] = useState(
    state.currentUser.services.parking || false,
  )
  const [parkingInfo, setParkingInfo] = useState(
    state.currentUser.services.parkingInfo || '',
  )
  const [dogFriendly, setDogFriendly] = useState(
    state.currentUser.services.dogFriendly || false,
  )
  const [uploading, setUploading] = useState(false)
  const [successfullUpload, setSuccessfullUpload] = useState(false)

  const [disableDaypass, setDisableDaypass] = useState(true);

  const handleDropin = (e: any) => {
    const price: number = Number(e.target.value)
    if (price >= 0 && price <= 1000) setDropInPrice(price)
    if (price < 0) setDropInPrice(0)
    if (price > 1000) setDropInPrice(1000)
  }


  const handleSave = async () => {
    setUploading(true)
    setSuccessfullUpload(false)
    const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
    setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
      reportNonBlockingError(err, state, 'Services -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })'),
    )
  }
  const handleSaveBtn = async () => {
    handleSave()
      .then(() => {
        setTimeout(() => {
          setUploading(false)
          setSuccessfullUpload(true)
        }, 1000)
      })
      .catch((err) => {
        reportNonBlockingError(err, state, 'Services -> handleSaveBtn -> handleSave()')
      })
  }

  const popover = (info: string) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>{info}</Popover.Body>
      </Popover>
    )
  }

  const handleDayPassLinks = (e: any, i: number) => {
    let link = e.target.value
    let copy = [...dayPassLinks]
    copy[i] = link
    if (copy[copy.length - 1].length && copy.length < 4) copy.push('')
    setdayPassLinks(copy)
  }
  const handleInstagramUsername = (e: any) => {
    setInstagramUsername(e.target.value);
  }

  // Disable daypass links if user is not premium.
  useEffect(() => {
    if (state.currentUser.moreGymsPremium?.status === 'active' || state.currentUser.moreGymsPremium?.status === 'trialing') {
      setDisableDaypass(false);
    }
  }, [state.currentUser.moreGymsPremium])

  useEffect(() => {
    const sanitizedDaypassLinks = dayPassLinks.filter((link: string) => link.length > 0)
    setSuccessfullUpload(false)
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        dayPassLink: sanitizedDaypassLinks,
        services: {
          dropInPrice,
          trainersAvailable,
          wifiPW,
          wifiAvailable,
          dayPass,
          openGym,
          openGymInfo,
          nutritionCoaching,
          personalFilming,
          classesOffered,
          classesOfferedInfo,
          weightPlatforms,
          parking,
          parkingInfo,
          dogFriendly,
        },
      },
    })
    // eslint-disable-next-line
  }, [
    dropInPrice,
    trainersAvailable,
    wifiPW,
    wifiAvailable,
    dayPass,
    openGym,
    openGymInfo,
    nutritionCoaching,
    personalFilming,
    classesOffered,
    classesOfferedInfo,
    weightPlatforms,
    parking,
    parkingInfo,
    dogFriendly,
    dayPassLinks
  ])
  const dayPassIcon = '<svg id="Layer_5" data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs><style>.cls-1 { stroke-width: 0px;}</style></defs><path class="cls-1" d="M68.78,21.2l-7.89-7.89c-.78-.78-2.05-.78-2.83,0L13.31,58.06c-.78.78-.78,2.05,0,2.83l7.83,7.83c.69.69,1.77.78,2.56.22,2.1-1.48,4.95-1.23,6.76.59,1.82,1.82,2.06,4.66.59,6.76-.56.79-.46,1.88.22,2.56l7.83,7.83c.39.39.9.59,1.41.59s1.02-.2,1.41-.59l44.75-44.75c.78-.78.78-2.05,0-2.83l-7.89-7.89c-.66-.66-1.68-.77-2.47-.29-2.09,1.3-4.77.99-6.52-.75-1.74-1.74-2.05-4.42-.75-6.52.49-.79.37-1.81-.29-2.47ZM76.99,35.07l5.46,5.46-11.73,11.73-2.83-2.83c-.78-.78-2.05-.78-2.83,0s-.78,2.05,0,2.83l2.83,2.83-27.36,27.36-5.34-5.34c1.58-3.47.9-7.62-1.88-10.4-2.78-2.78-6.94-3.47-10.4-1.88l-5.34-5.34,27.36-27.36,2.83,2.83c.39.39.9.59,1.41.59s1.02-.2,1.41-.59c.78-.78.78-2.05,0-2.83l-2.83-2.83,11.73-11.73,5.46,5.46c-1.37,3.4-.62,7.33,2.06,10.01s6.6,3.43,10.01,2.06Z"/><path class="cls-1" d="M56.34,37.89c-.78-.78-2.05-.78-2.83,0s-.78,2.05,0,2.83l5.77,5.77c.39.39.9.59,1.41.59s1.02-.2,1.41-.59c.78-.78.78-2.05,0-2.83l-5.77-5.77Z"/></svg>';
  const openGymIcon = '<svg id="Layer_5" data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="M86.78,43.48l-10.41-10.41c-.75-.75-2.08-.75-2.83,0l-3.96,3.96-2.2-2.2c-.75-.75-2.08-.75-2.83,0l-6.31,6.31c-.78.78-.78,2.05,0,2.83l7.4,7.4-14.28,14.28-7.4-7.4c-.78-.78-2.05-.78-2.83,0l-6.31,6.3c-.38.38-.59.88-.59,1.41s.21,1.04.59,1.41l2.2,2.2-3.96,3.96c-.38.38-.59.88-.59,1.41s.21,1.04.59,1.41l10.41,10.41c.38.38.88.59,1.41.59s1.04-.21,1.41-.59l3.96-3.96,2.2,2.2c.38.38.88.59,1.41.59s1.04-.21,1.41-.59l6.31-6.31c.78-.78.78-2.05,0-2.83l-7.4-7.4,14.28-14.28,7.4,7.4c.38.38.88.59,1.41.59s1.04-.21,1.41-.59l6.31-6.31c.78-.78.78-2.05,0-2.83l-2.2-2.2,3.96-3.96c.78-.78.78-2.05,0-2.83ZM44.9,82.54l-7.58-7.58,2.55-2.55,7.58,7.58-2.55,2.55ZM53.88,80.77l-2.2-2.2s0,0,0,0l-3.04-3.04-9.57-9.57,3.48-3.48,14.81,14.81-3.48,3.48ZM77.29,57.36l-7.4-7.4s0,0,0,0,0,0,0,0l-7.4-7.4,3.48-3.48,2.2,2.2s0,0,0,0l10.41,10.41s0,0,0,0l2.2,2.2-3.48,3.48ZM72.4,39.86l2.55-2.55,7.58,7.58-2.55,2.55-7.58-7.59Z"/></svg>';
  const filmingApprovedIcon = '<svg id="Layer_5" data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="M86.38,40.53c-.62-.36-1.37-.36-1.99-.01l-19.24,10.88v-8.28c0-1.1-.9-2-2-2h-3.5c3.37-2.95,5.5-7.27,5.5-12.08,0-8.87-7.22-16.08-16.08-16.08-7.83,0-14.36,5.62-15.79,13.04-1.93-1.71-4.46-2.76-7.24-2.76-6.04,0-10.95,4.91-10.95,10.95,0,2.63.94,5.05,2.49,6.95h-2.96c-1.1,0-2,.9-2,2v41.01c0,1.1.9,2,2,2h48.53c1.1,0,2-.9,2-2v-8.27l19.23,10.94c.31.17.65.26.99.26s.69-.09,1.01-.27c.62-.36.99-1.02.99-1.73v-42.8c0-.71-.38-1.37-.99-1.73ZM49.07,16.94c6.66,0,12.08,5.42,12.08,12.08s-5.42,12.08-12.08,12.08-12.08-5.42-12.08-12.08,5.42-12.08,12.08-12.08ZM38.49,41.11h-4c.65-.79,1.19-1.67,1.6-2.62.7.96,1.51,1.84,2.4,2.62ZM19.09,34.17c0-3.83,3.12-6.95,6.95-6.95s6.95,3.12,6.95,6.95-3.12,6.95-6.95,6.95-6.95-3.12-6.95-6.95ZM61.15,82.12H16.62v-37.01h44.53v37.01ZM83.38,81.62l-18.22-10.37v-15.26l18.22-10.31v35.94Z"/><path class="cls-1" d="M50.87,51.53l-16.21,16.65-6.36-8.22c-.68-.87-1.93-1.03-2.81-.36-.87.68-1.03,1.93-.36,2.81l7.77,10.04c.35.46.89.74,1.47.77.04,0,.08,0,.11,0,.54,0,1.06-.22,1.43-.6l17.82-18.29c.77-.79.75-2.06-.04-2.83s-2.06-.75-2.83.04Z"/></svg>';
  const classesIcon = '<svg id="Layer_5" data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="M85.37,14.01H30.27c-1.1,0-2,.9-2,2v5.16c-3.42,1.1-5.91,4.31-5.91,8.09,0,1.74.53,3.36,1.43,4.71h-1.19c-5.5,0-9.97,4.47-9.97,9.97v14.53c0,3.33,2.71,6.04,6.04,6.04.43,0,.85-.05,1.26-.14v15.43c0,3.4,2.77,6.17,6.17,6.17,1.61,0,3.07-.62,4.17-1.63,1.1,1.01,2.56,1.63,4.17,1.63,3.4,0,6.17-2.77,6.17-6.17v-18.21h13.91l-11.26,21.46c-.51.98-.14,2.19.84,2.7.3.16.61.23.93.23.72,0,1.42-.39,1.77-1.07l9.02-17.2v16.26c0,1.1.9,2,2,2s2-.9,2-2v-16.26l9.02,17.2c.36.68,1.05,1.07,1.77,1.07.31,0,.63-.07.93-.23.98-.51,1.35-1.72.84-2.7l-11.26-21.46h24.24c1.1,0,2-.9,2-2V16.01c0-1.1-.9-2-2-2ZM30.86,24.76c2.48,0,4.5,2.02,4.5,4.5s-2.02,4.5-4.5,4.5-4.5-2.02-4.5-4.5,2.02-4.5,4.5-4.5ZM36.6,79.8c0,1.2-.97,2.17-2.17,2.17s-2.17-.97-2.17-2.17v-20.21c0-1.1-.9-2-2-2s-2,.9-2,2v20.21c0,1.2-.97,2.17-2.17,2.17s-2.17-.97-2.17-2.17v-35.5c0-1.1-.9-2-2-2s-2,.9-2,2v15.76c-.36.29-.79.45-1.26.45-1.12,0-2.04-.92-2.04-2.04v-14.53c0-3.29,2.68-5.97,5.97-5.97h16.8c.43,0,.85-.14,1.19-.39l10.83-8.05c.9-.67,2.19-.48,2.86.42.33.44.46.97.38,1.51-.08.54-.36,1.01-.8,1.34l-11.91,8.85c-.35.26-.77.4-1.22.4h-.89l-1.13-.06c-.54-.02-1.09.17-1.49.54s-.63.9-.63,1.45v35.82ZM83.37,57.6h-42.76v-11.54h.13c1.29,0,2.57-.42,3.6-1.19l11.91-8.85c1.3-.96,2.14-2.37,2.37-3.97.24-1.6-.17-3.19-1.13-4.48-1.98-2.67-5.78-3.23-8.45-1.25l-10.3,7.66h-.81c.9-1.35,1.43-2.97,1.43-4.71,0-4.21-3.07-7.7-7.09-8.37v-2.88h51.1v39.59Z"/></svg>';
  return (
    <div className="services-body">
      <div className="services-section">
        <div className='service-heading'>
          <div className='column'>
            <h4>Services</h4>
            <p>Showcase the services that make you stand out.</p>
          </div>
          <button onClick={handleSaveBtn} className='custom-button-second'>
            {'Save  '}
            {uploading && (
              <Spinner size="sm" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
            {successfullUpload && <i className="bi bi-check2"></i>}
          </button>
        </div>
        <div className="column-container">
          <div className="service-column">
            <div className="service-row">
              <div className="info-span">
                <div className="info-border">
                  <OverlayTrigger
                    trigger={['hover', 'focus', 'click']}
                    placement="right"
                    overlay={popover(
                      parseAmenityKeysForDisplay('dayPass').info,
                    )}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: dayPassIcon }}
                    />
                  </OverlayTrigger>
                </div>
                <span>Day Pass Available</span>
              </div>
              <label className="amenity-switch">
                <input
                  id="daypass-checkbox"
                  checked={dayPass}
                  onChange={() => setdayPass(!dayPass)}
                  type="checkbox"
                  disabled={state.currentUser.stripeData == null}
                ></input>
                <span className="amenity-switch-slider"></span>
              </label>
            </div>
            <div className="service-row">
              <div className="info-span">
                <div className="info-border">
                  <OverlayTrigger
                    trigger={['hover', 'focus', 'click']}
                    placement="right"
                    overlay={popover(
                      parseAmenityKeysForDisplay('openGym').info,
                    )}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: openGymIcon }}
                    />
                  </OverlayTrigger>
                </div>
                <span>Open gym</span>
              </div>
              <label className="amenity-switch">
                <input
                  id="open-gym-checkbox"
                  checked={openGym}
                  onChange={() => setopenGym(!openGym)}
                  type="checkbox"
                ></input>
                <span className="amenity-switch-slider"></span>
              </label>
            </div>
            <div className="service-row">
              <InputGroup className="mb-3">
                <FloatingLabel label="Open gym times & details...">
                  <Form.Control
                    as="textarea"
                    style={{ height: '100px' }}
                    placeholder="Open Gym Hours"
                    value={openGymInfo}
                    onChange={(e: { target: { value: any } }) => setOpenGymInfo(e.target.value)}
                  />
                </FloatingLabel>
              </InputGroup>
            </div>
          </div>
          <div className="service-column">
            <div className="service-row">
              <div className="info-span">
                <div className="info-border">
                  <OverlayTrigger
                    trigger={['hover', 'focus', 'click']}
                    placement="right"
                    overlay={popover(
                      parseAmenityKeysForDisplay('filming').info,
                    )}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: filmingApprovedIcon }}
                    />
                  </OverlayTrigger>
                </div>
                <span>Personal Filming Allowed</span>
              </div>
              <label className="amenity-switch">
                <input
                  id="filming-checkbox"
                  checked={personalFilming}
                  onChange={() => setPersonalFilming(!personalFilming)}
                  type="checkbox"
                ></input>
                <span className="amenity-switch-slider"></span>
              </label>
            </div>
            <div className="service-row">
              <div className="info-span">
                <div className="info-border">
                  <OverlayTrigger
                    trigger={['hover', 'focus', 'click']}
                    placement="right"
                    overlay={popover(
                      parseAmenityKeysForDisplay('classes').info,
                    )}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: classesIcon }}
                    />
                  </OverlayTrigger>
                </div>
                <span>Classes Offered</span>
              </div>
              <label className="amenity-switch">
                <input
                  id="classes-checkbox"
                  checked={classesOffered}
                  onChange={() => setClassesOffered(!classesOffered)}
                  type="checkbox"
                ></input>
                <span className="amenity-switch-slider"></span>
              </label>
            </div>
            <div className="service-row">
              <InputGroup className="mb-3">
                <FloatingLabel label="Classes you offer...">
                  <Form.Control
                    as="textarea"
                    style={{ height: '100px' }}
                    placeholder="Classes you offer..."
                    value={classesOfferedInfo}
                    onChange={(e: { target: { value: any } }) => setClassesOfferedInfo(e.target.value)}
                  />
                </FloatingLabel>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
