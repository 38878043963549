import React, { useEffect, useState, useRef, FormEvent } from 'react'
import { Form, FloatingLabel } from 'react-bootstrap'
import { doc, setDoc, GeoPoint } from '@firebase/firestore'
import { Spinner } from 'react-bootstrap'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useStoreContext } from '../../../Utilities/globalState'
import './styles.scss'
import { db } from '../../../firebase-config'
import { parseDataForDB, reportNonBlockingError } from '../../../Utilities/helpers'
import { GYMS_REF } from '../../../Utilities/dbRefs'
import { useNavigate } from 'react-router-dom'

export const Signup2 = () => {
  const [state, dispatch] = useStoreContext()
  const [pageSuccess, setPageSuccess] = useState(false)
  const [gymName, setGymName] = useState(state.currentUser.gymName || '')
  const [address, setAddress] = useState(state.currentUser.address || '')
  const [zipCode, setZipCode] = useState(state.currentUser.zipCode || '')
  const [stateInUs, setStateInUs] = useState(state.currentUser.stateInUs || '')
  const [website, setWebsite] = useState(state.currentUser.website || '')
  const [phoneNumber, setPhoneNumber] = useState(
    state.currentUser.phoneNumber || '',
  )
  const [geoPoint, setGeopoint] = useState<GeoPoint | null>(state.currentUser.geoPoint || null)
  const [openHours, setOpenHours] = useState(
    state.currentUser.openHours || {
      0: { open: '09:00', close: '17:00', isOpen: false, day: 'Monday' },
      1: { open: '09:00', close: '17:00', isOpen: false, day: 'Tuesday' },
      2: { open: '09:00', close: '17:00', isOpen: false, day: 'Wednesday' },
      3: { open: '09:00', close: '17:00', isOpen: false, day: 'Thursday' },
      4: { open: '09:00', close: '17:00', isOpen: false, day: 'Friday' },
      5: { open: '09:00', close: '17:00', isOpen: false, day: 'Saturday' },
      6: { open: '09:00', close: '17:00', isOpen: false, day: 'Sunday' },
    },
  )
  const [uploading, setUploading] = useState(false)
  const [successfullUpload, setSuccessfullUpload] = useState(false)
  const nextRef = useRef<HTMLButtonElement | null>(null)
  const navigate = useNavigate()

  const { ref: autoRef } = usePlacesWidget<HTMLInputElement>({
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
    apiKey: 'AIzaSyD8MmzA7tO8dxZjPpDMCz1wfOYflRShx04' + "&callback=Function.prototype",
    onPlaceSelected: (place) => {
      handleAddressSelect(place)
    }
  })

  const handleAddressSelect = (place: any) => {
    const newAddress = place.formatted_address || ''
    setAddress(newAddress)
    
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const newGeoPoint = new GeoPoint(lat, lng)
    setGeopoint(newGeoPoint)
  }



  const handleSave = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const form = event.currentTarget
    if (!form.checkValidity()) {
      setPageSuccess(true)
      return
    }

    setUploading(true)
    setSuccessfullUpload(false)
    const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
    setDoc(gymRef, parseDataForDB(state), { merge: true })
      .then(() => {
        setTimeout(() => {
          setUploading(false)
          setSuccessfullUpload(true)
          dispatch({
            type: 'LOGIN_STATUS',
            loggedIn: true,
          })
        }, 1000)
      })
      .catch((err) => reportNonBlockingError(err, state, 'Signup2 -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })'))
  }

  useEffect(() => {
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        gymName,
        address,
        zipCode,
        stateInUs,
        website,
        phoneNumber,
        openHours,
        geoPoint
      },
    })
    // eslint-disable-next-line
  }, [gymName, address, zipCode, stateInUs, website, phoneNumber, openHours, geoPoint])

  return (
    <div id="signup-2" className="signup-2-body">
      <div className='step-counter'>
        <h1>Gym Information</h1>
        <div className='step'>
          <p>Step 2 of 2</p>
          <div className='step-indicator'>
            <div className='step1'></div>
            <div className='step2'></div>
          </div>
        </div>
      </div>
      <Form
        className="signup-2-form"
        autoComplete={'' + Math.random()}
        onSubmit={handleSave}
        noValidate
        validated={pageSuccess}
      >
        <div className='description'>
          <h5>Location Name</h5>
          <p>Add your business name to help people find you quickly and easily.</p>
        </div>
        <Form.Group
          className="mb-3"
          controlId="formGymName"
          id="signup-gymname"
        >
          <FloatingLabel label="Gym Name" controlId="floatingGymName">
            <Form.Control
              value={gymName}
              autoComplete="off"
              onChange={(e: { target: { value: any } }) => setGymName(e.target.value)}
              type="text"
              placeholder="Gym Name"
              required
            />
          </FloatingLabel>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <div className='description'>
          <h5>Address</h5>
          <p>Provide the address where your services are available.</p>
        </div>
        <Form.Group
          className="mb-3"
          controlId="formAddress"
          id="signup-address"
        >
          <FloatingLabel label="Address" controlId="floatingAddress">
            <input
              ref={autoRef}
              autoComplete="off"
              id="address"
              defaultValue={address}
              type="text"
              className='form-control'
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </FloatingLabel>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <div className='description'>
          <h5>Phone Number</h5>
          <p>Add a number so people can easily reach you.</p>
        </div>
        <Form.Group
          className="mb-3"
          controlId="formPhoneNumber"
          id="signup-phone"
        >
          <FloatingLabel label="Phone Number" controlId="floatingPhoneNumber">
            <Form.Control
              value={phoneNumber}
              autoComplete="off"
              onChange={(e: { target: { value: any } }) => setPhoneNumber(e.target.value)}
              type="phone"
              placeholder="Phone Number"
              required
            />
          </FloatingLabel>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <div className='description'>
          <h5>Website</h5>
          <p>Provide your website so we can verify, your business.</p>
        </div>
        <Form.Group
          className="mb-3"
          controlId="formWebsite"
          id="signup-website"
        >
          <FloatingLabel label="Website" controlId="floatingWebsite">
            <Form.Control
              value={website}
              autoComplete="off"
              onChange={(e: { target: { value: any } }) => setWebsite(e.target.value)}
              type="text"
              placeholder="Website"
            />
          </FloatingLabel>
        </Form.Group>

        <div className="save-dashboard-button">
          <button
            id="save-button"
            className='custom-button'
            type='submit'
          >
            {'Sign up  '}
            {uploading && (
              <Spinner size="sm" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
            {successfullUpload && <i className="bi bi-check2"></i>}
          </button>
        </div>
      </Form>
    </div>
  )
}


/// Old Sign up Form June 10th 2024


// import React, { useEffect, useState, useRef, FormEvent } from 'react'
// import { Form, FloatingLabel } from 'react-bootstrap'
// import { doc, setDoc, GeoPoint } from '@firebase/firestore'
// import { Spinner } from 'react-bootstrap'
// import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete'
// import { useStoreContext } from '../../../Utilities/globalState'
// import './styles.scss'
// import { db } from '../../../firebase-config'
// import { parseDataForDB, reportNonBlockingError } from '../../../Utilities/helpers'
// import { GYMS_REF } from '../../../Utilities/dbRefs'
// import {
//   setKey,
//   fromAddress,
// } from 'react-geocode'
// import { useNavigate } from 'react-router-dom'
// import { report } from 'process'

// export const Signup2 = () => {
//   const [state, dispatch] = useStoreContext()
//   const [pageSuccess, setPageSuccess] = useState(false)
//   const [gymName, setGymName] = useState(state.currentUser.gymName || '')
//   const [address, setAddress] = useState(state.currentUser.address || '')
//   const [zipCode, setZipCode] = useState(state.currentUser.zipCode || '')
//   const [stateInUs, setStateInUs] = useState(state.currentUser.stateInUs || '')
//   const [website, setWebsite] = useState(state.currentUser.website || '')
//   const [phoneNumber, setPhoneNumber] = useState(
//     state.currentUser.phoneNumber || '',
//   )
//   const [geoPoint, setGeopoint] = useState<any>({})
//   const [openHours, setOpenHours] = useState(
//     state.currentUser.openHours || {
//       0: { open: '09:00', close: '17:00', isOpen: false, day: 'Monday' },
//       1: { open: '09:00', close: '17:00', isOpen: false, day: 'Tuesday' },
//       2: { open: '09:00', close: '17:00', isOpen: false, day: 'Wednesday' },
//       3: { open: '09:00', close: '17:00', isOpen: false, day: 'Thursday' },
//       4: { open: '09:00', close: '17:00', isOpen: false, day: 'Friday' },
//       5: { open: '09:00', close: '17:00', isOpen: false, day: 'Saturday' },
//       6: { open: '09:00', close: '17:00', isOpen: false, day: 'Sunday' },
//     },
//   )
//   const [uploading, setUploading] = useState(false)
//   const [successfullUpload, setSuccessfullUpload] = useState(false)
//   const nextRef = useRef<HTMLButtonElement | null>(null)
//   const navigate = useNavigate();

//   const { ref: autoRef }: { ref: React.RefObject<HTMLInputElement> | null } = usePlacesWidget({
//     options: {
//       types: ['address'],
//       componentRestrictions: { country: 'us' },
//     },
//     apiKey: 'AIzaSyD8MmzA7tO8dxZjPpDMCz1wfOYflRShx04',
//     onPlaceSelected: (place) => {
//       setUserAddress(place)
//     }
//   });

//   function setUserAddress(place: any) {
//     const newAddress = place?.formatted_address || place;
//     setAddress(newAddress);
//     handleAddressBlur(newAddress);
//   }

//   const updateHours = (e: any, day: number, type: string) => {
//     let time = e.target.value
//     if (type === 'isOpen') {
//       let temp = openHours[day as keyof typeof openHours]
//       temp.isOpen = !temp.isOpen
//       setOpenHours({
//         ...openHours,
//         [day]: {
//           ...temp,
//         },
//       })
//     } else {
//       setOpenHours({
//         ...openHours,
//         [day]: {
//           ...openHours[day],
//           [type]: time,
//         },
//       })
//     }
//   }

//   const handleSave = async (event: FormEvent<HTMLFormElement>) => {
//     const form = event.currentTarget;
//     if (!form.checkValidity()) {
//       event.preventDefault();
//       event.stopPropagation();
//     }
//     setPageSuccess(true)
    
//     if (form.checkValidity()) {
//       setUploading(true)
//       setSuccessfullUpload(false)
//       const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
//       setDoc(gymRef, parseDataForDB(state), { merge: true })
//         .then((res) => {
//           setTimeout(() => {
//             setUploading(false)
//             setSuccessfullUpload(true)
//             dispatch({
//               type: 'LOGIN_STATUS',
//               loggedIn: true,
//             })
//           }, 1000)
//         })
//         .catch((err) => reportNonBlockingError(err, state, 'Hours -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })'))
//     }
//   }


//   function handleAddressBlur(newPlace: any) {
//     if (!newPlace || !newPlace.length) return
//     setKey('AIzaSyBsH9qX6ADQaQHuNdLZFiJNWdC-T3QqodE')
//     fromAddress(newPlace).then(
//       (response: any) => {
//         const { lat, lng } = response.results[0].geometry.location
//         const newGeoPoint = new GeoPoint(lat, lng);
//         setGeopoint(newGeoPoint)
//       },
//       (error: any) => {
//         console.error('no address available', error)
//       },
//     )

//   }


//   useEffect(() => {
//     setSuccessfullUpload(false)
//     dispatch({
//       type: 'SET_CURRENT_USER',
//       currentUser: {
//         ...state.currentUser,
//         gymName,
//         address,
//         zipCode,
//         stateInUs,
//         website,
//         phoneNumber,
//         openHours,
//         geoPoint
//       },
//     })
//     // eslint-disable-next-line
//   }, [gymName, address, zipCode, stateInUs, website, phoneNumber, openHours])

//   useEffect(() => {
//     if (autoRef.current) setAddress(autoRef.current.value)
//   }, [autoRef])

//   return (
//     <div id="signup-2" className="signup-2-body">
//       <div className='step-counter'>
//         <h1>Gym information</h1>
//         <div className='step'>
//           <p>Step 2 of 2</p>
//           <div className='step-indicator'>
//             <div className='step1'></div>
//             <div className='step2'></div>
//           </div>
//         </div>
//       </div>
//       <Form
//         className="signup-2-form"
//         autoComplete={'' + Math.random()}
//         onSubmit={handleSave}
//         noValidate
//         validated={pageSuccess}
//       >
//         <Form.Group
//           className="mb-3"
//           controlId="formGymName"
//           id="signup-gymname"
//         >
//           <FloatingLabel label="Gym Name" controlId="floatingGymName">
//             <Form.Control
//               value={gymName}
//               autoComplete="off"
//               onChange={(e: { target: { value: any } }) => setGymName(e.target.value)}
//               type="text"
//               placeholder="Gym Name"
//               required
//             />
//           </FloatingLabel>
//           <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         </Form.Group>

//         <Form.Group
//           className="mb-3"
//           controlId="formAddress"
//           id="signup-address"
//         >
//           <FloatingLabel label="Address" controlId="floatingAddress">
//             <input
//               ref={autoRef}
//               autoComplete="off"
//               // autoComplete={'' + Math.random()}
//               id="address" defaultValue={address}
//               type="text" className='form-control'
//               onChange={(e) => setUserAddress(e.target.value)}
//               onBlur={(e) => setUserAddress(e.target.value)}
//               required
//             />
//           </FloatingLabel>
//           <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         </Form.Group>

//         <Form.Group
//           className="mb-3"
//           controlId="formPhoneNumber"
//           id="signup-phone"
//         >
//           <FloatingLabel label="Phone Number" controlId="floatingPhoneNumber">
//             <Form.Control
//               value={phoneNumber}
//               autoComplete="off"
//               onChange={(e: { target: { value: any } }) => setPhoneNumber(e.target.value)}
//               type="phone"
//               placeholder="Phone Number"
//               required
//             />
//           </FloatingLabel>
//           <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         </Form.Group>

//         <Form.Group
//           className="mb-3"
//           controlId="formWebsite"
//           id="signup-website"
//         >
//           <FloatingLabel label="Website" controlId="floatingWebsite">
//             <Form.Control
//               value={website}
//               autoComplete="off"
//               onChange={(e: { target: { value: any } }) => setWebsite(e.target.value)}
//               type="text"
//               placeholder="Website"
//             />
//           </FloatingLabel>

//         </Form.Group>
//         <div className="save-dashboard-button">
          
//           <button
//             id="save-button"
//             className='custom-button'
//             type='submit'
//           >
//             {'Submit  '}
//             {uploading && (
//               <Spinner size="sm" animation="border" role="status">
//                 <span className="visually-hidden">Loading...</span>
//               </Spinner>
//             )}
//             {successfullUpload && <i className="bi bi-check2"></i>}
//           </button>

//         </div>
//       </Form>
//     </div>
//   )
// }

