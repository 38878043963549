import { useEffect } from 'react'
import { useNavigate, useBlocker } from 'react-router-dom';
import "./styles.scss"

interface NavigationPromptProps {
    when: boolean;
    setWhen: (value: boolean) => void;
    message: string;
    save?: (() => void) | null;

}
export default function NavigationPrompt({ when, setWhen, message, save }: NavigationPromptProps) {

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            when &&
            currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (blocker && !when) {
            blocker.reset?.();
        }
    }, [when]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (when) {
                e.preventDefault();
                setWhen(false);
                blocker.reset?.();
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [when]);

    return (<>
        {blocker.state === "blocked" ? (
            <div className="navigation-prompt-background">
                <div className="navigation-prompt">
                    <p>{message}</p>
                    <div className="button-group">

                        <button className='custom-button custom-button-second' onClick={() => {
                            setWhen(false);
                            blocker.proceed?.()
                        }}>
                            Proceed
                        </button>
                        <button className='custom-button custom-button-second' onClick={() => blocker.reset?.()}>
                            Cancel
                        </button>
                        {save && <button className='custom-button' onClick={() => {
                            save();
                            blocker.proceed?.();
                        }}>Save</button>}
                    </div>
                </div>
            </div>
        ) : null}
    </>
    )
}
