import React, { useState } from 'react';
import { ConfigProvider, Tabs, TabsProps } from 'antd';
import { PageTemplate } from '../../PageTemplate';
import { Amenities } from './Amenities';
import { Services } from './Services';
import { Hours } from './Hours';
import Equipment from './Equipment';
import { Details } from './Details';

import './styles.scss';

export const InfoPages: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Amenities',
      children: <Amenities />,
    },
    {
      key: '2',
      label: 'Services',
      children: <Services />,
    },
    {
      key: '3',
      label: 'Hours',
      children: <Hours />,
    },
    {
      key: '4',
      label: 'Equipment',
      children: <Equipment />,
    },
    {
      key: '5',
      label: 'Details',
      children: <Details />,
    },
  ];

  const onChange = (key: string) => {
    console.log('Selected Tab Key:', key);
  };

  const themeSettings = {
    token: {
      colorPrimary: '#000000',
      cardBg: '#1a1a1a',
      inkBarColor: '#ffffff',
      itemColor: 'rgba(255, 255, 255, 0.7)',
      itemActiveColor: '#ffffff',
      itemHoverColor: '#cccccc',
      cardPadding: '10px 20px',
    },
  };

  return (
    <ConfigProvider theme={themeSettings}>
      <PageTemplate>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </PageTemplate>
    </ConfigProvider>
  );
};

