import React, { useState, useEffect } from 'react'
import { PageTemplate } from '../../PageTemplate'
import { AddMemberModal } from './AddMemberModal'
import { EditMemberModal } from './EditMemberModal'
import { collection, getDocs, query, where } from '@firebase/firestore'
import { db } from '../../../firebase-config'
import { useStoreContext } from '../../../Utilities/globalState'
import { GYM_MEMBERS_REF } from '../../../Utilities/dbRefs'
import './styles.scss'
import { Table } from 'antd';

export const Members = () => {
  const [state, dispatch] = useStoreContext()
  const [addUserModal, setAddUserModal] = useState(false)
  const [editMemberModal, setEditMemberModal] = useState(-1)
  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';

    // Remove all non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber;
  };
  const loadClasses = async () => {
    const memberList: any[] = [];
    try {
      const memberQuery = query(
        collection(db, GYM_MEMBERS_REF),
        where('gymId', '==', state.currentUser.gymId),
      )
      const memberListSnapshot = await getDocs(memberQuery)
      memberListSnapshot.forEach((doc) => {
        memberList.push({ ...doc.data(), id: doc.id })
      })
      dispatch({
        type: 'SET_GYM_MEMBERS',
        gymMembers: [...memberList],
      })
    } catch {
      console.log('error loading classes')
    }
  }

  useEffect(() => {
    loadClasses()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (photo: string) => (
        photo.length ? (
          <img src={photo} alt="member" className="member-photo" style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }} />
        ) : (
          'No Photo'
        )
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'fName',
      key: 'fName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lName',
      key: 'lName',
    },
    // {
    //   title: 'Classes',
    //   dataIndex: 'classesAttended',
    //   key: 'classesAttended',
    //   render: (classesAttended: any[]) => classesAttended.length,
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => formatPhoneNumber(phone),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any, index: number) => (
        <button className="custom-button-fourth" onClick={() => {
          setEditMemberModal(index);
        }}>
          View Profile
        </button>
      ),
    },
  ];

  return (
    <PageTemplate>
      <div className="members-body">
        <div className='members-heading'>
          <div className='column'>
            <h1>Trainers</h1>
            <p>Showcase the friendly faces that make your business thrive!</p>
          </div>
          <button className="custom-button" onClick={() => setAddUserModal(true)}>
            ADD TRAINER <i className="bi bi-plus-square"></i>
          </button>
        </div>
        <Table
          columns={columns}
          dataSource={state?.gymMembers}
          rowKey="id"
          pagination={{ pageSize: 5 }} 
        />
        <AddMemberModal show={addUserModal} hide={setAddUserModal} />
        {state?.gymMembers?.length ? (
          <EditMemberModal show={editMemberModal} hide={setEditMemberModal} />
        ) : null}
      </div>
    </PageTemplate>
  )
}
