import React from 'react'
import { useStoreContext } from '../../Utilities/globalState'
import { parseDataForState } from '../../Utilities/helpers'
import { NavDropdown } from 'react-bootstrap'
import './styles.scss'
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../Utilities/ErrorPage';

export const PageTemplate = ({ children }: any) => {
  const [state, dispatch] = useStoreContext()

  const handleGymSelect = (gym: any) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: parseDataForState(gym),
    })
    // window.location.reload();
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {state?.gymsList?.length > 1 ? (
        <div className="gym-select-dropdown">
          <NavDropdown
            id="gym-dropdown"
            title={state?.currentUser?.gymName}
            drop="down"
          >
            {state?.gymsList?.map((gym: any) => state.currentUser.gymId !== gym[0] && (
              <NavDropdown.Item key={gym[0]} onClick={() => handleGymSelect(gym)}>
                {gym[1]['Name']}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </div>
      ) : null}
      <div
        className={
          'page-template ' +
          (state.navCollapse === 3 && ' page-collapse ') +
          (state.navCollapse === 2 && ' page-expand ') +
          (state.navCollapse === 4 && ' page-expanded ')
        }
      >
        {React.Children.map(children, (child: any) => {
          return <>{child}</>
        })}
      </div>
    </ErrorBoundary>
  )
}
