import React, { useEffect, useState } from 'react'
import { Spinner, Form, FloatingLabel, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useStoreContext } from '../../../../Utilities/globalState'
import { doc, setDoc } from '@firebase/firestore'
import { parseDataForDB, reportNonBlockingError } from '../../../../Utilities/helpers'
import { db } from '../../../../firebase-config'
import './styles.scss'
import { GYMS_REF } from "../../../../Utilities/dbRefs"
import { report } from 'process'

export const Hours = () => {
  const [state, dispatch] = useStoreContext()
  const [uploading, setUploading] = useState(false)
  const [successfullUpload, setSuccessfullUpload] = useState(false)
  const [appointmentOnly, setAppointmentOnly] = useState(
    state.currentUser.appointmentOnly || false,
  )
  const [open247, setOpen247] = useState(state.currentUser.open247 || false)
  const [openHours, setOpenHours] = useState(
    state.currentUser.openHours || {
      0: { open: '09:00', close: '17:00', isOpen: false, day: 'Monday' },
      1: { open: '09:00', close: '17:00', isOpen: false, day: 'Tuesday' },
      2: { open: '09:00', close: '17:00', isOpen: false, day: 'Wednesday' },
      3: { open: '09:00', close: '17:00', isOpen: false, day: 'Thursday' },
      4: { open: '09:00', close: '17:00', isOpen: false, day: 'Friday' },
      5: { open: '09:00', close: '17:00', isOpen: false, day: 'Saturday' },
      6: { open: '09:00', close: '17:00', isOpen: false, day: 'Sunday' },
    },
  )
  const isAtLeastOneDayOpen = (): boolean => {
    const hoursArray = Object.values(openHours) as { isOpen: boolean }[]
    return hoursArray.some((day) => day.isOpen)
  }

  const updateHours = (e: any, day: number, type: string) => {
    let time = e.target.value
    if (type === 'isOpen') {
      let temp = openHours[day as keyof typeof openHours]
      temp.isOpen = !temp.isOpen
      setOpenHours({
        ...openHours,
        [day]: {
          ...temp,
        },
      })
    } else {
      setOpenHours({
        ...openHours,
        [day]: {
          ...openHours[day],
          [type]: time,
        },
      })
    }
  }

  const copyHours = (sourceDay: number, targetDay: number) => {
    setOpenHours({
      ...openHours,
      [targetDay]: {
        ...openHours[targetDay],
        open: openHours[sourceDay].open,
        close: openHours[sourceDay].close,
        isOpen: openHours[sourceDay].isOpen,
      },
    })
  }

  const handleSave = async () => {
    setUploading(true)
    setSuccessfullUpload(false)
    const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
    setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
      reportNonBlockingError(err, state, 'Hours -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })'),
    )
  }

  const handleSaveBtn = async () => {
    handleSave()
      .then(() => {
        setTimeout(() => {
          setUploading(false)
          setSuccessfullUpload(true)
        }, 1000)
      })
      .catch((err) => {
        reportNonBlockingError(err, state, 'Hours -> handleSaveBtn -> handleSave()')
      })
  }

  useEffect(() => {
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        open247,
        openHours,
        appointmentOnly
      },
    })
    // eslint-disable-next-line
  }, [openHours, appointmentOnly, open247])

  return (
    <div className="hours-body">
      <div className='hours-normal'>
        <div className='hours-heading'>
          <div className='column'>
            <h4>Business hours</h4>
            <p>Make sure you keep your hours up to date, so customers know when they can call or drop-in to your location.</p>
          </div>
          <button onClick={handleSaveBtn} className='custom-button-second'>
            {'Save  '}
            {uploading && (
              <Spinner size="sm" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
            {successfullUpload && <i className="bi bi-check2"></i>}
          </button>
        </div>
        <>
          {Object.keys(openHours).map((day: any, i) => {
            return (
              <div key={i} className="hours-row hours-row-time">
                <div className='row'>
                  <label className="hours-switch">
                    <input
                      onChange={(e) => updateHours(e, i, 'isOpen')}
                      id={`${openHours[day].day}-open-checkbox`}
                      type="checkbox"
                      checked={openHours[i].isOpen}
                    ></input>
                    <span className="hours-switch-slider"></span>
                  </label>
                  <h5>{openHours[day]['day']}</h5>
                </div>
                <div className='group'>
                  <Form.Group
                    className="mb-3 open-time"
                    controlId={`hours-${openHours[day].day}-open-time`}
                    id={`hours-${openHours[day].day}-open-time`}
                  >
                    <FloatingLabel
                      label="Open"
                      controlId={`floating${openHours[day].day}OpenTime`}
                    >
                      <Form.Control
                        type="time"
                        value={openHours[day].open}
                        placeholder="Open"
                        onChange={(e: any) => updateHours(e, i, 'open')}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 open-time"
                    controlId={`hours-${openHours[day].day}-close-time`}
                    id={`hours-${openHours[day].day}-close-time`}
                  >
                    <FloatingLabel
                      label="Close"
                      controlId={`floating${openHours[day].day}CloseTime`}
                    >
                      <Form.Control
                        type="time"
                        value={openHours[day].close}
                        placeholder="Close"
                        onChange={(e: any) => updateHours(e, i, 'close')}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <div className='copy-times'>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>Copy times</Tooltip>}
                    >
                      <DropdownButton id={`copy-times-dropdown-${i}`} title={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                      </svg>} variant={"none"} className="custom-dropdown-button">
                        <Dropdown.ItemText>Copy times from</Dropdown.ItemText>
                        {Object.keys(openHours).map((sourceDay: any, j) => (
                          <Dropdown.Item key={j} onClick={() => copyHours(j, i)}>
                            {openHours[sourceDay].day}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      </div>
      <div className='hours-heading'>
        <div className='column'>
          <h3>Special hours</h3>
          <p>If your gym hours are sporatic it's key to let potential customers know.</p>
        </div>
      </div>
      <div className='hours-special'>
        <div className="hours-row">
          <label className="hours-switch">
            <input
              id="appointment-checkbox"
              checked={appointmentOnly}
              onChange={() => setAppointmentOnly(!appointmentOnly)}
              type="checkbox"
            ></input>
            <span className="hours-switch-slider"></span>
          </label>
          <h5>Appointment only</h5>
        </div>
        <div className="hours-row">
          <label className="hours-switch">
            <input
              id="open-checkbox"
              checked={open247}
              onChange={() => setOpen247(!open247)}
              type="checkbox"
            ></input>
            <span className="hours-switch-slider"></span>
          </label>
          <h5>Open 24/7</h5>
        </div>
      </div>
    </div>
  )
}
