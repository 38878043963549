import React, { useState, useRef, useEffect } from 'react';
import { PageTemplate } from '../../PageTemplate'
import Analytics from './Analytics'
import ToDoList from './ToDoList'
import Welcome from './Welcome'
import Help from './Help';
import Numbers from './Numbers';
import './styles.scss'
import { StandardLonghandProperties } from 'csstype';
import { useStoreContext } from '../../../Utilities/globalState'
import { Tour } from 'antd';
import type { TourProps } from 'antd';

export const Home = () => {
  const [state, dispatch] = useStoreContext()
  const homeRowRef = useRef<HTMLDivElement>(null);
  const [flexDirection, setFlexDirection] = useState<StandardLonghandProperties['flexDirection']>('row');

  const businessStatisticsRef = useRef(null);
  const analyticsRef = useRef(null);
  const toDoListRef = useRef(null);
  const helpRef = useRef(null);

  const [open, setOpen] = useState<boolean>(false);
  const nextButtonProps = {
    style: {
      backgroundColor: 'black',
      borderColor: '#272829',
      color: 'white',
    },
  };

  const prevButtonProps = {
    style: {
      backgroundColor: 'white',
      borderColor: '#ccc',
      color: 'black',
    },
  };
  const indicatorsRender = (current: number, total: number) => {
    const indicators = [];
    for (let i = 0; i < total; i++) {
      indicators.push(
        <span
          key={i}
          style={{
            display: 'inline-block',
            width: 7,
            height: 7,
            margin: '0 2px',
            backgroundColor: i === current ? 'black' : '#d9d9d9',
            borderRadius: '50%',
          }}
        ></span>
      );
    }
    return <div>{indicators}</div>;
  };
  const [steps, setSteps] = useState<TourProps['steps']>([
    {
      title: 'Business Statistics',
      description: 'Watch your business grow with key stats that show you how your business is performing on MoreGyms.',
      target: () => businessStatisticsRef.current,
      nextButtonProps,
      prevButtonProps,
    },
    {
      title: 'Page Analytics',
      description: 'Here you are able to view how many times your page has been viewed. You can toggle between mobile and web views by clicking the red or black boxes.',
      target: () => analyticsRef.current,
      nextButtonProps,
      prevButtonProps,
    },
    {
      title: 'To-Do List',
      description: "Manage your tasks here. This list is to help you prioritze the important tasks needed to be completed on your business's page.",
      target: () => toDoListRef.current,
      nextButtonProps,
      prevButtonProps,
    },
    {
      title: 'Help Center',
      description: 'The help center gives you a peace of mind while using MoreGyms. Here you can re-take the tour, read our owner docs, or contact support.',
      target: () => helpRef.current,
      nextButtonProps,
      prevButtonProps,
    },
  ]);
  useEffect(() => {
    if (!homeRowRef || !homeRowRef.current) return;

    if (homeRowRef!.current!.offsetWidth > 900) {
      setFlexDirection('row');
    } else {
      setFlexDirection('column');
    }

  }, [state.navCollapse]);


  return (
    <PageTemplate>
      <div className='home-body'>
        <div className='home-row' ref={homeRowRef} style={{ flexDirection: flexDirection }}>
          <div className='home-colum left-side'>
            <Welcome />
            <div ref={businessStatisticsRef}>
              <Numbers />
            </div>
            <div ref={analyticsRef}>
              <Analytics />
            </div>
          </div>
          <div className='right-side'>
            <div ref={toDoListRef}>
              <ToDoList />
            </div>
            <div ref={helpRef}>
              <Help setOpen={setOpen} />
            </div>
          </div>
        </div>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
          indicatorsRender={indicatorsRender}

        />
      </div>
    </PageTemplate>
  )
}
