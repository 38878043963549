import { useState } from 'react';
import { ConfigProvider, Tabs } from 'antd';
import { PageTemplate } from '../../PageTemplate';
import { Photos } from './Photos';
import { Video } from './Video';
import './styles.scss';

export const Media = () => {
    const [page, setPage] = useState(1);

    // Define the tab items for Ant Design Tabs
    const items = [
        {
            key: '1',
            label: 'Photos',
            children: <Photos />,
        },
        {
            key: '2',
            label: 'Video',
            children: <Video />,
        },
    ];
    const themeSettings = {
        token: {
          colorPrimary: '#000000',
          cardBg: '#1a1a1a',
          inkBarColor: '#ffffff',
          itemColor: 'rgba(255, 255, 255, 0.7)',
          itemActiveColor: '#ffffff',
          itemHoverColor: '#cccccc',
          cardPadding: '10px 20px',
        },
      };
    return (
        <ConfigProvider theme={themeSettings}>
            <PageTemplate>
                <Tabs
                    activeKey={page.toString()}
                    onChange={(key) => setPage(Number(key))}
                    items={items}
                />
            </PageTemplate>
        </ConfigProvider>
    );
};
