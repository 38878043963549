import React from 'react'
import { useStoreContext } from '../../../Utilities/globalState'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Signup1 } from '../Signup1'
import { Signup2 } from '../Signup2'
import { Signup3 } from '../Signup3'
import { Signup4 } from '../Signup4'
import './styles.scss'

export const SignupCarousel: React.FC<any> = () => {
  const [state] = useStoreContext()
  const navigate = useNavigate()
  const hash = window.location.hash

  function renderPage() {
    switch (hash) {
      case '#signup-1':
        return <Signup1 />
      case '#signup-2':
        return <Signup2 />
      default:
        return <Signup1 />
    }
  }

  useEffect(() => {
    if (state && state.loggedIn) {
      navigate('/')
    }
  }, [state, navigate])
    
  // }
  return (
    <div className="signup-carousel-body">
      {/* <style>{keyFrameStyle}</style> */}
      <div className="signup-carousel-list">
        <div className="signup-carousel-item">
          {renderPage()}
        </div>
      </div>
      {/* {page > 0 && (
        <button
          type="button"
          className="signup-button"
          id="previous-button"
          href={pageIds[page - 1]}
          onClick={() => {
            setPage(page -1)
          }}
        >
          Previous
        </button>
      )}
      {page < 3 && (
        <button
          type="button"
          className="signup-button"
          id="next-button"
          href={pageIds[page+1]}
          onClick={() => {
            setPage(page +1)
          }}
        >
          Next
        </button>
      )} */}
    </div>
  )
}
