import React, { useState, useEffect, useRef } from 'react';
import { useStoreContext } from '../../../../Utilities/globalState';
import { ref, getDownloadURL, uploadBytesResumable } from '@firebase/storage';
import { storage } from '../../../../firebase-config';
import { doc, setDoc, collection, query, where, getDocs } from '@firebase/firestore';
import { parseDataForDB, reportNonBlockingError } from '../../../../Utilities/helpers';
import { db } from '../../../../firebase-config';
import { GYMS_REF } from '../../../../Utilities/dbRefs';
import {  CloudUploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import './style.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Dragger } = Upload;

export const Video = () => {
    const [state, dispatch] = useStoreContext();
    const [uploadProgress, setUploadProgress] = useState(0);
    const videoUploadRef = useRef<HTMLInputElement>(null);
    const [localState, setLocalState] = useState({
        videoUrl: '',
    });
    const notify = () => toast.success("Upload Successful!");
    const uploadError = () => toast.error("Upload Error!");
    const nonVideo = () => toast.error("Upload Error!");

    const handleSave = async () => {
        const gymRef = doc(db, GYMS_REF, state.currentUser.gymId);
        setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
            reportNonBlockingError(err, state, "Video -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })")
        );
        const existingGymQuery = query(
            collection(db, GYMS_REF),
            where('Admin ID', '==', state.currentUser.gymOwnerId)
        );
        const existingGym = await getDocs(existingGymQuery);
        let gyms: any = [];
        existingGym.forEach((gym: any) => gyms.push([gym.id, gym.data()]));
        dispatch({
            type: 'SET_GYMS',
            gymsList: gyms,
        });
    };

    const uploadVideo = (file: File) => {
        if (!file.type.includes("video")) {
            // message.error("This must be a video file!");
            nonVideo();
            return;
        }
        const videoRef = ref(storage, `GymWalkthrough/${state.currentUser.gymId}`);
        const uploadTask = uploadBytesResumable(videoRef, file);

        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        }, (error) => {
            reportNonBlockingError(error, state, "Video -> uploadVideo -> uploadTask.on('state_changed', (snapshot)");
            message.error(`${file.name} file upload failed.`);
            uploadError();
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setLocalState({
                    ...localState,
                    videoUrl: downloadURL,
                });
                // message.success(`${file.name} file uploaded successfully.`);
                notify();
            });
        });
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        beforeUpload: file => {
            uploadVideo(file);
            return false; // Prevent auto upload by Ant Design, since we handle it ourselves
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        setLocalState({
            videoUrl: state.currentUser.videoUrl || '',
        });
    }, [state]);

    useEffect(() => {
        if (!localState.videoUrl.length || state.currentUser.videoUrl === localState.videoUrl) return;
        dispatch({
            type: 'SET_CURRENT_USER',
            currentUser: {
                ...state.currentUser,
                ...localState,
            },
        });
    }, [localState]);

    useEffect(() => {
        if (state.currentUser.videoUrl.length && state.currentUser.videoUrl !== localState.videoUrl) {
            handleSave();
        }
    }, [state]);

    return (
        <div className="videos-body">
            <div className="media-heading">
                <div className="column">
                    <h3>Video</h3>
                    <p>A video lets potential visitors experience your gym before they arrive.</p>
                </div>
                {/* <div className="video-buttons">
                    <input
                        hidden
                        ref={videoUploadRef}
                        id="walkthrough-video"
                        type="file"
                        accept="video/*"
                        onChange={(e) => {
                            if (e.target.files) {
                                uploadVideo(e.target.files[0]);
                            }
                        }}
                    />
                    <button className="custom-button" onClick={() => videoUploadRef.current?.click()}>
                        ADD A VIDEO <i className="bi bi-plus"></i>
                    </button>
                </div> */}
            </div>
            <div className="videos">
                <Dragger {...props}>
                    <CloudUploadOutlined className="ant-upload-drag-icon" />

                    <p className="ant-upload-text">Click or drag a video to upload</p>
                    <p className="ant-upload-hint">
                        Please upload a walkthrough or welcome video.
                    </p>
                </Dragger>
                <div className="video">
                    {localState.videoUrl.length ? <video className="video-player" controls src={localState.videoUrl}></video> : null}
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};











// import "./style.scss"
// import React, { useState, useEffect, useRef } from 'react'
// import { useStoreContext } from '../../../../Utilities/globalState'

// import { ref, getDownloadURL, uploadBytesResumable } from '@firebase/storage'
// import { storage } from '../../../../firebase-config'
// import { doc, setDoc, collection, query, where, getDocs, } from '@firebase/firestore'
// import { parseDataForDB, reportNonBlockingError } from '../../../../Utilities/helpers'
// import { db } from '../../../../firebase-config'
// import { GYMS_REF } from '../../../../Utilities/dbRefs'


// export const Video = () => {
//     const [state, dispatch] = useStoreContext()
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const videoUploadRef = useRef<HTMLInputElement>(null)
//     const [localState, setLocalState] = useState({
//         videoUrl: '',
//     })

//     const handleSave = async () => {
//         const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
//         setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
//             reportNonBlockingError(err, state, "Video -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })"),
//         )
//         // This updates the state of the gymsList.
//         const existingGymQuery = query(
//             collection(db, GYMS_REF),
//             where('Admin ID', '==', state.currentUser.gymOwnerId),
//         )
//         const existingGym = await getDocs(existingGymQuery)
//         let gyms: any = []
//         existingGym.forEach((gym: any) => gyms.push([gym.id, gym.data()]))
//         dispatch({
//             type: 'SET_GYMS',
//             gymsList: gyms,
//         })
//     }

//     const uploadVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
//         if (e.target.files != null) {
//             if (!e.target.files[0].type.includes("video")) {
//                 alert("This must be a video file!")
//                 return;
//             }
//             const videoRef = ref(storage, `GymWalkthrough/${state.currentUser.gymId}`)
//             if (e.target.files[0] !== null) {
//                 const uploadTask = uploadBytesResumable(videoRef, e.target.files[0]);

//                 uploadTask.on('state_changed', (snapshot) => {
//                     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                     setUploadProgress(progress);
//                     // console.log('Upload is ' + uploadProgress + '% done');
//                 }, (error) => {
//                     reportNonBlockingError(error, state, "Video -> uploadVideo -> uploadTask.on('state_changed', (snapshot)")
//                 }, () => {
//                     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//                         // console.log('File available at', downloadURL);
//                         setLocalState({
//                             ...localState,
//                             videoUrl: downloadURL,
//                         })
//                     });
//                 });
//             }
//         }
//     }

//     useEffect(() => {
//         setLocalState({
//             videoUrl: state.currentUser.videoUrl || '',
//         })
//     }, [state])

//     useEffect(() => {
//         if (!localState.videoUrl.length || state.currentUser.videoUrl == localState.videoUrl) return;
//         dispatch({
//             type: 'SET_CURRENT_USER',
//             currentUser: {
//                 ...state.currentUser,
//                 ...localState,
//             },
//         })
//     }, [localState])

//     useEffect(() => {
//         if (state.currentUser.videoUrl.length && state.currentUser.videoUrl !== localState.videoUrl) {
//             handleSave()
//         }
//     }, [state])


//     return (
//         <div className="videos-body">

//             <div className="media-heading">
//                 <div className="column">
//                     <h3>Walkthrough </h3>
//                     <p>Adding a walkthrough allows people to see your gym before they come in</p>
//                 </div>
//                 <div className="video-buttons">
//                     <input
//                         hidden
//                         ref={videoUploadRef}
//                         id="walkthrough-video"
//                         onChange={uploadVideo}
//                         type="file"
//                         accept="video/*"
//                     />
//                     <button className="custom-button" onClick={() => {
//                         videoUploadRef.current?.click()
//                     }}>
//                         ADD A VIDEO <i className="bi bi-plus"></i>
//                     </button>
//                 </div>

//             </div>
//             <div className="videos">
//                 <div className="video">
//                     {localState.videoUrl.length ? <video className="video-player" controls src={localState.videoUrl}></video> : null}
//                 </div>
//                 {/* {uploadProgress > 0 && <div className="progress-bar">
//                     <div className="progress-bar-fill" style={{ width: `${uploadProgress}%` }}>
//                         {uploadProgress >= 100 ? 'Upload Complete' : `${uploadProgress}%`}
//                     </div>
//                 </div>} */}
//             </div>
//         </div>
//     )
// }
