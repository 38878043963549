import React, { useState, useEffect } from 'react'
import { useStoreContext } from '../../../../Utilities/globalState'
import { Spinner, Form, InputGroup, FloatingLabel } from 'react-bootstrap'
import { usePlacesWidget } from 'react-google-autocomplete'
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable } from '@firebase/storage'
import { storage } from '../../../../firebase-config'
import { doc, setDoc, GeoPoint, collection, query, where, getDocs, } from '@firebase/firestore'
import { parseDataForDB, reportNonBlockingError } from '../../../../Utilities/helpers'
import { db } from '../../../../firebase-config'
import './styles.scss'
import { GYMS_REF } from '../../../../Utilities/dbRefs'
import PremiumSubscription from './PremiumSubscription'

export const Info = () => {
  const [state, dispatch] = useStoreContext()
  const [uploading, setUploading] = useState(false)
  const [successfullUpload, setSuccessfullUpload] = useState(false)
  const [localState, setLocalState] = useState({
    gymName: '',
    address: '',
    zipCode: '',
    phoneNumber: '',
    website: '',
    geoPoint: state.currentUser.geoPoint,
    videoUrl: '',
  })

  const [address, setAddress] = useState(state.currentUser.address || '')
  const [geoPoint, setGeopoint] = useState(state?.currentUser?.geoPoint || {})
  const [gymLogoURL, setGymLogoURL] = useState(
    state.currentUser.gymLogoURL || null,
  )
  const [gymHeaderURL, setGymHeaderURL] = useState(
    state.currentUser.gymHeaderURL || null,
  )
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setLocalState({
      gymName: state.currentUser.gymName,
      address: state.currentUser.address,
      zipCode: state.currentUser.zipCode,
      phoneNumber: state.currentUser.phoneNumber,
      website: state.currentUser.website,
      geoPoint: state.currentUser.geoPoint,
      videoUrl: state.currentUser.videoUrl || '',
    })
  }, [state])

  const updateUserInfo = (element: any) => {

    const key = element.target.id
    const newLocalState = {
      ...localState,
      [key]: element.target.value,
    }
    setLocalState(newLocalState)
  }

  const saveUserInfo = () => {
    setUploading(true);
    setTimeout(() => {
      setUploading(false);
      setSuccessfullUpload(true);
    }, 1000);
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        ...localState,
        gymLogoURL,
        gymHeaderURL,
        geoPoint,
      },
    })
  }

  const handleSave = async () => {
    const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
    setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
      reportNonBlockingError(err, state, 'Info -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })'),
    )

    // This updates the state of the gymsList.
    const existingGymQuery = query(
      collection(db, GYMS_REF),
      where('Admin ID', '==', state.currentUser.gymOwnerId),
    )
    const existingGym = await getDocs(existingGymQuery)
    let gyms: any = []
    existingGym.forEach((gym: any) => gyms.push([gym.id, gym.data()]))
    dispatch({
      type: 'SET_GYMS',
      gymsList: gyms,
    })
  }

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      if (e.target.id === 'logo-image') {
        const imageRef = ref(storage, `LogoImages/${state.currentUser.gymId}`)

        if (e.target.files[0] !== null) {
          uploadBytes(imageRef, e.target.files[0], state.currentUser.gymId)
            .then((res) => {
              getDownloadURL(res.ref)
                .then((r) => {
                  setGymLogoURL(r)
                })
                .catch((err) => reportNonBlockingError(err, state, 'Info -> uploadImage -> getDownloadURL(res.ref)'))
            })
            .catch((err) => {
              reportNonBlockingError(err, state, 'Info -> uploadImage -> uploadBytes(imageRef, e.target.files[0], state.currentUser.gymId)')
            })
        }
      } else {
        const imageRef = ref(storage, `HeaderImages/${state.currentUser.gymId}`)
        if (e.target.files[0] !== null)
          uploadBytes(imageRef, e.target.files[0], state.currentUser.gymId)
            .then((res) => {
              getDownloadURL(res.ref)
                .then((r) => {
                  setGymHeaderURL(r)
                })
                .catch((err) => reportNonBlockingError(err, state, 'Info -> uploadImage -> getDownloadURL(res.ref)'))
            })
            .catch((err) => {
              reportNonBlockingError(err, state, 'Info -> uploadImage -> uploadBytes(imageRef, e.target.files[0], state.currentUser.gymId)')
            })
      }
    }
  }

  const uploadVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      if (!e.target.files[0].type.includes("video")) {
        alert("This must be a video file!")
        return;
      }
      const videoRef = ref(storage, `GymWalkthrough/${state.currentUser.gymId}`)
      if (e.target.files[0] !== null) {
        const uploadTask = uploadBytesResumable(videoRef, e.target.files[0]);

        uploadTask.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          // console.log('Upload is ' + uploadProgress + '% done');
        }, (error) => {
          reportNonBlockingError(error, state, 'Info -> uploadVideo -> uploadTask.on(state_changed)')
        }, () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log('File available at', downloadURL);
            setLocalState({
              ...localState,
              videoUrl: downloadURL,
            })
          });
        });
      }
    }
  }

  const { ref: autoRef }: { ref: React.RefObject<HTMLInputElement> | null } = usePlacesWidget({
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
    apiKey: 'AIzaSyD8MmzA7tO8dxZjPpDMCz1wfOYflRShx04' + "&callback=Function.prototype",
    onPlaceSelected: (place) => {
      // handleAutocomplete(place)
      setAddress(place?.formatted_address || localState.address || '')
      const lat = place?.geometry?.location?.lat() || 0
      const lng = place?.geometry?.location?.lng() || 0
      const newGeoPoint = new GeoPoint(lat, lng)
      setGeopoint(newGeoPoint)
    }
  });

  useEffect(() => {
    setLocalState({
      ...localState,
      address,
      geoPoint,
    })
  }
    , [address, geoPoint])

  useEffect(() => {
    const logoRef = ref(storage, `LogoImages/${state.currentUser.gymId}`)
    const headerRef = ref(storage, `HeaderImages/${state.currentUser.gymId}`)

    getDownloadURL(logoRef)
      .then((url) => {
        setGymLogoURL(url)
      })
      .catch((err) => console.log('No logo image available'))
    // getDownloadURL(headerRef)
    //   .then((url) => {
    //     setGymHeaderURL(url)
    //   })
    //   .catch((err) => console.log('No header image available'))
  }, [])

  useEffect(() => {
    handleSave()
  }, [state.currentUser.gymName,
  state.currentUser.address,
  state.currentUser.zipCode,
  state.currentUser.phoneNumber,
  state.currentUser.website,
  state.currentUser.gymLogoURL,
  state.currentUser.gymHeaderURL,
  state.currentUser.videoUrl,])

  return (
    <div className="edit-gym-info-body">
      {/* <PremiumSubscription /> */}
      <div className='info-heading'>
        <div className='group'>
          <h2>Account Details</h2>
          <p>This information is crucial for customers to locate and contact your business.</p>
        </div>
        <button onClick={() => saveUserInfo()} className='custom-button-second'>
          {'Save  '}
          {uploading && (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {successfullUpload && <i className="bi bi-check2"></i>}
        </button>
      </div>
      <hr />
      <div className="info-body">
        <div className="info-user-photo-container">
          <label htmlFor="logo-image" className="logo-label">
            {gymLogoURL ? (
              <img className="logo-image" src={gymLogoURL} alt="Gym Logo" />
            ) : (
              <div className="logo-placeholder">Add a photo</div>
            )}
          </label>
          <input
            id="logo-image"
            onChange={uploadImage}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            style={{ display: 'none' }}
          />
        </div>
        <div className="info-gym-info">
          <InputGroup size="sm" className="mb-3">
            <FloatingLabel label="Gym name">
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                value={localState.gymName}
                placeholder="Gym name"
                id="gymName"
                onChange={(e: any) => updateUserInfo(e)}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div className="info-gym-info">
          <InputGroup size="sm" className="mb-3">
            <FloatingLabel label="Address">
              <input
                ref={autoRef}
                autoComplete="off"
                name={'address' + Math.random() * 10000}
                id="address"
                placeholder="Address"
                defaultValue={address}
                type="text"
                className='form-control'
              />
            </FloatingLabel>
          </InputGroup>
        </div>

        <div className="info-gym-info">
          <InputGroup size="sm" className="mb-3">
            <FloatingLabel label="Phone number">
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                value={localState.phoneNumber}
                placeholder="Phone number"
                id="phoneNumber"
                onChange={(e: any) => updateUserInfo(e)}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div className="info-gym-info">
          <InputGroup size="sm" className="mb-3">
            <FloatingLabel label="Website">
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                value={localState.website}
                placeholder="Website"
                id="website"
                onChange={(e: any) => updateUserInfo(e)}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
      </div>
    </div>
  )
}
