import React from 'react'
import Graph from '../Graph'

export default function Analytics() {
  return (
    <>
    <Graph />
    </>
  )
}
