import React, { useState } from 'react'
import { Form, InputGroup, Spinner, Alert } from 'react-bootstrap'
import { useStoreContext } from '../../../../Utilities/globalState'
import { doc, setDoc } from '@firebase/firestore'
import { db } from '../../../../firebase-config'
import { GYM_FEEDBACK_REF } from '../../../../Utilities/dbRefs'
import './styles.scss'
import { reportNonBlockingError } from '../../../../Utilities/helpers'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const Feedback = () => {
  const [state] = useStoreContext()
  const [comments, setComments] = useState('')
  const [uploading, setUploading] = useState(false)
  const [successfullUpload, setSuccessfullUpload] = useState(false)
  const [commentErr, setCommentErr] = useState(false)
  const notify = () => toast.error("We need a little bit more than that!");

  const formSubmit = () => {
    setUploading(true)
    setCommentErr(false)
    if (comments.length > 0) {
      const feedbackData = {
        email: state.currentUser.email,
        gymId: state.currentUser.gymId,
        content: comments,
        date: new Date(),
      }
      const gymRef = doc(db, GYM_FEEDBACK_REF, `${Date.now()}`)
      setDoc(gymRef, feedbackData, { merge: true }).catch((err) =>
        reportNonBlockingError(err, state, 'Feedback -> formSubmit -> setDoc(gymRef, feedbackData, { merge: true })'),
      )
      setTimeout(() => {
        setUploading(false)
        setSuccessfullUpload(true)
      }, 1000)
    } else {
      notify();
      setCommentErr(true);
      setUploading(false)
    }
  }

  return (
    <div className="settings-body">
      <Form className="comment-form">
        <InputGroup>
          <InputGroup.Text className='username-style'id="basic-addon1">From: </InputGroup.Text>
          <Form.Control
            disabled
            value={state.currentUser.email}
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <InputGroup>
          <Form.Control
            value={comments}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
              setComments(e.target.value)
              setCommentErr(false)
            }}
            placeholder="Questions? Want some new features? Let us know here!"
            rows={10}
            as="textarea"
            aria-label="With textarea"
            style={{ resize: 'none' }}
          />
        </InputGroup>
        {/* {commentErr && (
          <Alert variant="danger">We need a little more than that!</Alert>
        )} */}
        <button className='custom-button' type="button" onClick={formSubmit}>
          {'Send  '}
          {uploading && (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {successfullUpload && <i className="bi bi-check2"></i>}
        </button>
      </Form>
      <ToastContainer/>
    </div>
  )
}
