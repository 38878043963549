import React, { useState, useEffect } from 'react';
import { auth, db } from '../../../firebase-config'
import { signInWithEmailAndPassword } from '@firebase/auth'
import { collection, query, where, getDocs } from '@firebase/firestore'
import { InputGroup, Form, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../Utilities/globalState'
import { parseDataForState } from '../../../Utilities/helpers'
import Logo from '../../../Images/newMagfitLogo.png'
import JoshFernandez from '../../../Images/login-images/3.png'
import TheoHaig from '../../../Images/login-images/2.png'
import TaylorLowe from '../../../Images/login-images/1.png'

import { Carousel } from 'antd';

import { GYMS_REF } from '../../../Utilities/dbRefs'
import './styles.scss'

export const Login = () => {
  const [state, dispatch] = useStoreContext()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hidePW, setHidePW] = useState(true)
  const [loginErr, setLoginErr] = useState(false)
  const [gymLookupErr, setGymLookupErr] = useState(false)
  const navigate = useNavigate()

  const handleLogin = async () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user

        user.getIdToken().then(async (data) => {
          updateToken(data)


          const existingGymQuery = query(
            collection(db, GYMS_REF),
            where('Admin ID', '==', user.uid),
          )
          const existingGym = await getDocs(existingGymQuery)

          if (!existingGym.empty) {
            let gyms: any = []
            existingGym.forEach((gym: any) => gyms.push([gym.id, gym.data()]))
            dispatch({
              type: 'SET_CURRENT_USER',
              currentUser: parseDataForState(gyms[0]),
            })
            dispatch({
              type: 'SET_GYMS',
              gymsList: gyms,
            })
            dispatch({
              type: 'LOGIN_STATUS',
              loggedIn: true,
            })
            navigate('/home')
          } else {
            setGymLookupErr(true)
          }
        })
        // ...
      })
      .catch((error) => {
        window.alert("Invalid email or password")
        setLoginErr(true)
      })
  }

  const updateToken = (token: string) => {
    localStorage.setItem('magfitToken', JSON.stringify(token))
  }



  useEffect(() => {
    if (state && state.loggedIn) {
      navigate('/')
    }
  }, [state, navigate]);

  return (
    <div className="login-body">
      <div className='login-group'>
        <div className='login-cta'>
          <Carousel autoplay>
            <div className='carousel'>
              <img
                className="navigation-magfit-logo"
                src={JoshFernandez}
                alt="Josh Fernandez Owner Richard Russell CrossFit"
              />
            </div>
            <div className='carousel'>
              <img
                className="navigation-magfit-logo"
                src={TheoHaig}
                alt="Theo Haig at AKA in San Jose, CA"
              />
            </div>
            <div className='carousel'>
              <img
                className="navigation-magfit-logo"
                src={TaylorLowe}
                alt="Taylor Lowe at Westca Gym"
              />
            </div>
          </Carousel>
        </div>
        <div className="login-section">
          <Form>
            <div className='login-header'>
              <img id="login-magfit-logo" src={Logo} alt="magfitLogo" />
              <h2>Welcome Back</h2>
              <p>Login to your account</p>
            </div>
            {loginErr && (
              <Alert className="alert alert-danger">
                Wrong email or password!
              </Alert>
            )}
            {gymLookupErr && (
              <Alert className="alert alert-danger">
                No Gym Associated With That Account
              </Alert>
            )}
            <InputGroup className="mb-3">
              <InputGroup.Text id="email-icon-box">
                <i className="bi bi-envelope"></i>
              </InputGroup.Text>
              <Form.Control
                id="email-input"
                type="email"
                placeholder="Email"
                aria-label="email"
                autoComplete="email"
                aria-describedby="email-icon-box"
                value={email}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="password-icon-box">
                <i className="bi bi-lock"></i>
              </InputGroup.Text>
              <Form.Control
                id="password-input"
                type={hidePW ? 'password' : 'text'}
                placeholder="Password"
                aria-label="password"
                autoComplete="current-password"
                aria-describedby="password-icon-box"
                value={password}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPassword(e.target.value)}
              />
              <InputGroup.Text
                onClick={() => setHidePW(!hidePW)}
                id="password-show-box"
              >
                {!hidePW && <i className="bi bi-eye"></i>}
                {hidePW && <i className="bi bi-eye-slash"></i>}
              </InputGroup.Text>
              <small id="passwordHelp" className="form-text text-muted">
                <Link to="/forgotpassword">Forgot Password?</Link>
              </small>
            </InputGroup>
            <button type='button' className='custom-button' onClick={handleLogin}>Login</button>
          </Form>
          <div className='sign-up-link'>
            <small>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
