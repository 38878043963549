import { useEffect, useRef, useState } from "react";
import "./style.scss";

import { Link } from 'react-router-dom';
import { useStoreContext } from "../../../../Utilities/globalState";
import { db, storage } from "../../../../firebase-config";
import { ADDITIONAL_PHOTOS_REF } from "../../../../Utilities/dbRefs";
import { doc, setDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from '@firebase/storage';
import { v4 as randomID } from 'uuid';
import { UPDATE_ADDITIONAL_PHOTOS } from "../../../../Utilities/actions";
import { parseDataForDB, reportNonBlockingError } from "../../../../Utilities/helpers";
import { GYMS_REF } from "../../../../Utilities/dbRefs";
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const Photos = () => {
    const [state, dispatch] = useStoreContext();
    const { additionalPhotos } = state.currentUser;
    const additionalPhotosRef = useRef<HTMLInputElement>(null);
    const [photoUrls, setPhotoUrls] = useState<string[]>([]);
    const [deletingIndex, setDeletingIndex] = useState<number | null>(null);
    const notify = () => toast.success("Upload Successful!");

    const imageDeleted = () => toast.error("Image Deleted!", {
        icon: <i className="bi bi-trash"></i>,
    });
    const [loading, setLoading] = useState(false);

    async function renderPhotos(): Promise<void> {
        const downloadURLs: string[] = [];
        for (let photo of additionalPhotos) {
            const photoUrl = await getDownloadURL(ref(storage, `${ADDITIONAL_PHOTOS_REF}/${photo}`))
            downloadURLs.push(photoUrl)
        }
        setPhotoUrls(downloadURLs);
        setLoading(false);
    }

    function uploadPhotosToFirebase(e: React.ChangeEvent<HTMLInputElement>) {
        let photoRefs: string[] = [...additionalPhotos];

        if (e.target?.files?.length) {
            const files = e.target.files;
            setTimeout(() => {
                notify();
            }, 1000)
            for (let i = 0; i < files.length; i++) {
                const imageID = randomID();
                const imageRef = ref(storage, `${ADDITIONAL_PHOTOS_REF}/${imageID}`);
                const uploadTask = uploadBytesResumable(imageRef, files[i]);
                photoRefs.push(imageID);
                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                }, (error) => {
                    photoRefs = photoRefs.filter((ref) => ref !== imageID);
                    reportNonBlockingError(error, state, "Photos -> uploadPhotosToFirebase -> uploadTask.on('state_changed')")
                }, () => {
                    renderPhotos()
                }
                );
            }
        }
        if (photoRefs.length) {
            dispatch({
                type: UPDATE_ADDITIONAL_PHOTOS,
                payload: photoRefs
            });
        }
    }

    async function handleRemoveImage(index: number) {
        const photoRefToDelete = additionalPhotos[index];
        setDeletingIndex(index);
        try {
            const imageRef = ref(storage, `${ADDITIONAL_PHOTOS_REF}/${photoRefToDelete}`);
            await deleteObject(imageRef);
            console.log('deleted');
            setTimeout(() => {
                setDeletingIndex(null);
                imageDeleted();
            }, 1000)
        } catch (error) {
            // reportNonBlockingError(error:, state, "Photos -> handleRemoveImage -> deleteObject(imageRef)");
            setDeletingIndex(null); // Reset the deletingIndex state if deletion fails
        }

        const photoRefs = additionalPhotos.filter((photo: any, i: number) => i !== index)
        dispatch({
            type: UPDATE_ADDITIONAL_PHOTOS,
            payload: photoRefs
        });
    }

    const handleSave = async () => {
        const gymRef = doc(db, GYMS_REF, state.currentUser.gymId);
        setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
            reportNonBlockingError(err, state, 'Photos -> handleSave -> setDoc(gymRef, parseDataForDB(state), { merge: true })'),
        );
    };

    useEffect(() => {
        if (additionalPhotos && additionalPhotos.length) {
            handleSave();
        }
    }, [additionalPhotos]);

    useEffect(() => {
        if (additionalPhotos && additionalPhotos.length) {
            setLoading(true);
            setTimeout(() => renderPhotos(), 1000);
        } else {
            setPhotoUrls([]);
            setLoading(false);
        }
    }, [additionalPhotos]);


    return (
        <div className="photos-body">
            <div className="media-heading">
                <div className="column">
                    <h3>Photos</h3>
                    <p>Give potential visitors a sneak peek of your gym before they arrive.</p>
                </div>
                <div className="photo-buttons">
                    <input hidden multiple={true} type="file" accept="image/png, image/gif, image/jpeg" ref={additionalPhotosRef} onChange={uploadPhotosToFirebase} />
                    <button className="custom-button" onClick={() => {
                        if (additionalPhotosRef.current) {
                            additionalPhotosRef.current.click();
                        }
                    }}>
                        <Link to="">ADD A PHOTO <i className="bi bi-plus"></i></Link>
                    </button>
                </div>
            </div>
            <div className="photos">
                {loading ? (
                    <div className="spinner-container">
                        <Spinner size="sm" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    photoUrls.length ? (
                        photoUrls.map((photo: string, index: number) => (
                            <div key={index} className="photo">
                                <button className="remove-button" onClick={() => handleRemoveImage(index)}>
                                    {index === deletingIndex ? (
                                        <Spinner size="sm" animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : (
                                        <i className="bi bi-x-lg"></i>
                                    )}
                                </button>
                                <img src={photo} alt="gym" />
                            </div>
                        ))
                    ) : (
                        <div className="photo">
                        </div>
                    )
                )}

            </div>
            <ToastContainer />
        </div>
    );
};