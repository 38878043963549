import React, { useState, useEffect } from 'react'
import {
  Modal,
  FloatingLabel,
  Form,
  Dropdown,
} from 'react-bootstrap'
import { parseDataForDB, reportNonBlockingError } from '../../../../Utilities/helpers'
import { addDoc, doc, collection, setDoc } from '@firebase/firestore'
import { db } from '../../../../firebase-config'
import { useStoreContext } from '../../../../Utilities/globalState'
import './styles.scss'
import { CLASS_REF, GYMS_REF } from '../../../../Utilities/dbRefs'

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(() => value + 1)
}
export const AddClassModal = ({
  day,
  show,
  setShow,
  modalToShow,
  index,
}: any) => {
  const [state, dispatch] = useStoreContext()
  let defaultState = {
    class: '',
    trainer: '',
    trainerId: '',
    trainerPhoto:'',
    time: '09:00',
    duration: '',
    recurring: false,
    attendees: [],
    attendeeIDs: [],
    maxAttendees:30,
    classDate: new Date(Date.now()),
    recurrID: 0,
    gymID: state.currentUser.gymId,
    completed: false,
    recurringDates: [
      { selected: false, day: 'Monday' },
      { selected: false, day: 'Tuesday' },
      { selected: false, day: 'Wednesday' },
      { selected: false, day: 'Thursday' },
      { selected: false, day: 'Friday' },
      { selected: false, day: 'Saturday' },
      { selected: false, day: 'Sunday' },
    ],
  }
  const [classIDList, setClassIDList] = useState<(string | null)[]>([])
  const [addClassData, setAddClassData] = useState({...defaultState})
  const showThisModal = modalToShow === index && show
  const forceUpdate = useForceUpdate()
  const handleRecurrDayChange = (i: any) => {
    const datesCopy = [...addClassData.recurringDates]
    datesCopy[i].selected = !datesCopy[i].selected
    setAddClassData({
      ...addClassData,
      recurringDates: datesCopy,
    })
  }

  const createClass = async (data: any) => {
    const allClassesCollectionRef = collection(db, CLASS_REF)
    const docRef = await addDoc(allClassesCollectionRef, data)
    if (docRef) return docRef.id
    else return null
  }

  const handleAddClassSubmit = () => {
    const timestampList = []
    const repeat = addClassData.recurring
      ? addClassData.recurringDates.length * 52
      : addClassData.recurringDates.length

    for (let i = 0; i < repeat; i++) {
      if (addClassData.recurringDates[i % 7].selected) {
        const date = new Date()
        let offset = (i + 1 - date.getDay()) % 7
        offset = offset < 0 ? offset + 7 : offset
        date.setDate(date.getDate() + offset + Math.floor(i / 7) * 7)
        timestampList.push(date)
      }
    }

    const recurrID = Date.now()
    let classTime: any[] = addClassData.time.split(':')
    ;[classTime[0], classTime[1]] = [Number(classTime[0]), Number(classTime[1])]
    
    for (let timestamp of timestampList) {
      timestamp.setHours(classTime[0], classTime[1])
      let classCopy = { ...addClassData }
      classCopy.classDate = timestamp
      classCopy.recurrID = recurrID
      createClass(classCopy)
        .then((data) => setClassIDList([...classIDList, data]))
        .then(() => setShow(false))
        .catch((err) => reportNonBlockingError(err, state, 'AddClassModal -> handleAddClassSubmit -> createClass'))
    }
    setAddClassData({...defaultState})
  }

  const saveStateToDB = () => {
    const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
    setDoc(gymRef, parseDataForDB(state), { merge: true }).catch((err) =>
      reportNonBlockingError(err, state, 'AddClassModal -> saveStateToDB -> setDoc(gymRef, parseDataForDB(state), { merge: true })' )
    )
  }

  const resetDates = () => {
    const datesCopy = [
      { selected: false, day: 'Monday' },
      { selected: false, day: 'Tuesday' },
      { selected: false, day: 'Wednesday' },
      { selected: false, day: 'Thursday' },
      { selected: false, day: 'Friday' },
      { selected: false, day: 'Saturday' },
      { selected: false, day: 'Sunday' },
    ]
    datesCopy[index].selected = true
    setAddClassData({
      ...addClassData,
      recurringDates: datesCopy,
    })
  }
  useEffect(() => {
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        classList: [...state.currentUser.classList, ...classIDList],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classIDList])

  useEffect(() => {
    index === modalToShow && saveStateToDB()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    resetDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addClassData.recurring, showThisModal])

  useEffect(() => {
    // Needs forced update due to deeply nested state.
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addClassData.recurringDates])

  return (
    <Modal show={showThisModal} onHide={() => setShow(false)}>
      <div className="add-class-modal-body">
        <h3>Add a class for {day}</h3>
        <FloatingLabel label="Class Type">
          <Form.Control
            className="add-class-input"
            type="text"
            placeholder="Class Type"
            value={addClassData.class}
            onChange={(e: { target: { value: any } }) =>
              setAddClassData({
                ...addClassData,
                class: e.target.value,
              })
            }
          ></Form.Control>
        </FloatingLabel>
        <FloatingLabel label="Trainer">
          <Form.Select
            className="add-class-input"
            // type="text"
            value={addClassData.trainer}
            onChange={(e: { target: { selectedIndex: any; children: { [x: string]: any }; value: any } }) => {
              const index = e.target.selectedIndex
              const el = e.target.children[index]
              const option = el.getAttribute('id')
              setAddClassData({
                ...addClassData,
                trainer: e.target.value,
                trainerId: option ? option : '',
              })
            }}
          >
            <option value="None">None</option>
            {state?.gymMembers?.map((member: any, i: number) => {
              if (member.trainer)
                return (
                  <option
                    id={`${member.id}`}
                    key={`member-option-${i}`}
                    value={`${member.fName}`}
                  >{`${member.fName} ${member.lName}`}</option>
                )
                else return null
            })}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Max Capacity">
          <Form.Control
            className="add-class-input"
            type="number"
            value={addClassData.maxAttendees}
            onChange={(e: { target: { value: any } }) =>
              setAddClassData({
                ...addClassData,
                maxAttendees: Number(e.target.value),
              })
            }
          ></Form.Control>
        </FloatingLabel>
        <FloatingLabel label="Time">
          <Form.Control
            className="add-class-input"
            type="time"
            value={addClassData.time}
            onChange={(e: { target: { value: any } }) =>
              setAddClassData({
                ...addClassData,
                time: e.target.value,
              })
            }
          ></Form.Control>
        </FloatingLabel>
        <FloatingLabel label="Duration">
          <Form.Select
            className="add-class-input"
            value={addClassData.duration}
            onChange={(e: { target: { value: any } }) =>
              setAddClassData({
                ...addClassData,
                duration: e.target.value,
              })
            }
          >
            <option>Open this select menu</option>
            <option value="0:30">0:30</option>
            <option value="1:00">1:00</option>
            <option value="1:30">1:30</option>
            <option value="2:00">2:00</option>
            <option value="2:30">2:30</option>
            <option value="3:00">3:00</option>
          </Form.Select>

        </FloatingLabel>
        <label className="recurring-label">
          Recurring Class:
          <label className="switch">
            <input
              onChange={(e) =>
                setAddClassData({
                  ...addClassData,
                  recurring: !addClassData.recurring,
                })
              }
              id={`recurring-open-checkbox`}
              type="checkbox"
              checked={addClassData.recurring}
            ></input>
            <span className="switch-slider"></span>
          </label>
        </label>
        {addClassData.recurring && (
          <Dropdown autoClose="outside">
            <Dropdown.Toggle
              className="add-class-input"
              variant="success"
              id="dropdown-basic"
            >
              Choose Days
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown">
              {addClassData?.recurringDates?.map(({ selected, day }, i) => {
                return (
                  <Dropdown.Item key={`day-${i}`}>
                    {' '}
                    <Form.Check
                      type="checkbox"
                      checked={selected}
                      label={day}
                      onChange={() => handleRecurrDayChange(i)}
                    />
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <button className='custom-button' onClick={handleAddClassSubmit}>Add +</button>
      </div>
    </Modal>
  )
}
