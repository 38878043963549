import React, { useState } from 'react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { InputGroup, Form } from 'react-bootstrap'
import Logo from '../../../Images/newMagfitLogo.png'
import './styles.scss'

export const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const auth = getAuth()

  function reset() {
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        // Password reset email sent!
      })
      .catch((error) => {
        // const errorCode = error.code
        // const errorMessage = error.message
      })
  }
  
  return (
    <div className="passkey-body">
      <div className="passkey-section">
        <img src={Logo} alt="magfitLogo" />
        {emailSent ? (
          <>
            <div className="success-body">
              <h4>
                You should be recieving an email to reset your password shortly
              </h4>
              <button className='custom-button' onClick={() => window.location.assign('/login')}>
                Back to login
              </button>
            </div>
          </>
        ) : (
          <>
            {' '}
            <InputGroup className="mb-3">
              <InputGroup.Text id="passkey-lock-box">
                <i className="bi bi-envelope"></i>
              </InputGroup.Text>
              <Form.Control
                id="passkey-input"
                type="email"
                placeholder="Enter Email"
                aria-label="email"
                aria-describedby="passkey-lock-box"
                value={email}
                onChange={(e: { target: { value: React.SetStateAction<string> } })=> setEmail(e.target.value)}
              />
            </InputGroup>
            <Form.Text className="text-muted">
              We'll send you an email to reset your password.
            </Form.Text>
            <button className='custom-button' onClick={()=>{
              reset()
              setEmailSent(true)
            }}>Reset</button>
          </>
        )}
      </div>
    </div>
  )
}
