import React, { useState, useEffect } from 'react'
import { Modal, FloatingLabel, Form } from 'react-bootstrap'
import { useStoreContext } from '../../../../Utilities/globalState'
import { doc, setDoc, deleteDoc } from '@firebase/firestore'
import { db } from '../../../../firebase-config'
import { GYM_MEMBERS_REF } from '../../../../Utilities/dbRefs'
import './styles.scss'
import { Badge, Descriptions } from 'antd';

export const EditMemberModal = ({ show, hide }: any) => {
  const [state, dispatch] = useStoreContext()
  const [editMode, setEditMode] = useState(false)
  const [member, setMember] = useState(show === -1 ? 0 : state.gymMembers[show])
  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';

    // Remove all non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber;
  };
  const items = [
    {
      key: '1',
      label: 'Name',
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
      children: `${state.gymMembers[show]?.fName} ${state.gymMembers[show]?.lName}`,
    },
    {
      key: '2',
      label: 'Email',
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },

      children: state.gymMembers[show]?.email,
    },
    {
      key: '3',
      label: 'Phone',
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
      children: formatPhoneNumber(state.gymMembers[show]?.phone),
    },
    {
      key: '5',
      label: 'Joined',
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
      children: member
        ? new Date(member.signupDate.seconds * 1000).toLocaleDateString()
        : '',
    },
    {
      key: '4',
      label: 'Status',
      children: <Badge status="processing" text="Active Trainer" />,
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
    },
    {
      key: '6',
      label: 'Emergency Contact Name',
      children: state.gymMembers[show]?.emergencyName,
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
    },
    {
      key: '7',
      label: 'Emergency Contact Number',
      children: formatPhoneNumber(state.gymMembers[show]?.emergencyPhone),
      span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
    },
  ];
  const removeMember = async () => {
    await deleteDoc(doc(db, GYM_MEMBERS_REF, member.id))
    window.location.reload()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'trainer') {
      setMember({
        ...member,
        [e.target.id]: e.target.value === 'on' ? true : false,
      })
    } else {
      setMember({
        ...member,
        [e.target.id]: e.target.value,
      })
    }
  }
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMember({ ...member, phone: value });
    }
  };
  const handleEmergencyPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMember({ ...member, emergencyPhone: value });
    }
  };

  const handleSaveMember = () => {
    const classRef = doc(db, GYM_MEMBERS_REF, member.id)
    setDoc(classRef, member, { merge: true })

    let memberCopy = state.gymMembers
    memberCopy[show] = member
    dispatch({
      type: 'SET_GYM_MEMBERS',
      gymMembers: [...memberCopy],
    })
    setEditMode(!editMode)
  }

  useEffect(() => {
    setMember(state.gymMembers[show])
    // eslint-disable-next-line
  }, [show])

  return (
    <>
      {member && (
        <Modal show={show !== -1} onHide={() => hide(-1)}>
          <div className="edit-member-body">
            {editMode && (
              <>
                {/* <FloatingLabel label="User Photo">
                  <Form.Control
                    className="edit-member-input"
                    type="text"
                    placeholder="User Photo"
                    defaultValue={member?.photo}
                    id="photo"
                    onChange={handleChange}
                  ></Form.Control>
                </FloatingLabel> */}
                <FloatingLabel label="First Name">
                  <Form.Control
                    className="edit-member-input"
                    type="text"
                    placeholder="First Name"
                    defaultValue={member?.fName}
                    id="fName"
                    onChange={handleChange}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel label="Last Name">
                  <Form.Control
                    className="edit-member-input"
                    type="text"
                    placeholder="Last Name"
                    defaultValue={member?.lName}
                    id="lName"
                    onChange={handleChange}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel label="Email">
                  <Form.Control
                    className="edit-member-input"
                    type="text"
                    placeholder="Email"
                    defaultValue={member?.email}
                    id="email"
                    onChange={handleChange}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel label="Phone">
                  <Form.Control
                    className="edit-member-input"
                    type="tel"
                    placeholder="Phone"
                    defaultValue={member?.phone}
                    id="phone"
                    autoComplete="tel"
                    maxLength={10}
                    value={member.phone}
                    onChange={handlePhoneChange}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel label="Emergency Contact Name">
                  <Form.Control
                    className="edit-member-input"
                    type="text"
                    placeholder="Emergency Contact Name"
                    id="emergencyName"
                    defaultValue={member?.emergencyName}
                    onChange={handleChange}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel label="Emergency Contact Phone">
                  <Form.Control
                    className="edit-member-input"
                    type="tel"
                    placeholder="Emergency Contact Phone"
                    id="emergencyPhone"
                    defaultValue={member?.emergencyPhone}
                    autoComplete="tel"
                    maxLength={10}
                    value={member.emergencyPhone}
                    onChange={handleEmergencyPhoneChange}
                  ></Form.Control>
                </FloatingLabel>
                {/* <Form.Check
                  type="checkbox"
                  id="trainer"
                  label="Gym Trainer"
                  defaultChecked={member?.trainer}
                  onChange={handleChange}
                />{' '} */}
              </>
            )}
            {!editMode && (
              <>
                {state.gymMembers[show]?.photo.length ? (
                  <img
                    src={state.gymMembers[show]?.photo}
                    className="member-modal-photo"
                    alt="member-modal"
                  />
                ) : (
                  ''
                )}
                <Descriptions
                  title=""
                  items={items}
                  column={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 }}
                />
              </>
            )}
            {editMode ? (
              <div className="delete-buttons">
                <button
                  className="custom-button member-button"
                  type="button"
                  onClick={handleSaveMember}
                >
                  SAVE
                </button>
                <button
                  className="custom-button-third"
                  type="button"
                  onClick={removeMember}
                >
                  DELETE TRAINER
                </button>
              </div>
            ) : (
              <button
                className="custom-button-second"
                type="button"
                onClick={() => setEditMode(true)}
              >
                Edit Info
              </button>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}
